import React from 'react'
import scss from './styles.module.scss'

interface IProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string
  options: { value: string; label: string }[]
  error?: string
}

const Select = React.forwardRef<HTMLSelectElement, IProps>(
  ({ label, options, error, ...props }, ref) => {
    return (
      <div className={scss.container}>
        {label && <label className={scss.label}>{label}</label>}
        <select
          ref={ref}
          className={`${scss.select} ${error ? scss.error : ''}`}
          {...props}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {error && <span className={scss.errorMessage}>{error}</span>}
      </div>
    )
  }
)

Select.displayName = 'Select'

export default Select
