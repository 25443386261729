import { useContext, useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import TableComponent from '../../../shared/Table'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'
import { OperatingUnitConfigs } from '../../../../utils/constants'
import { IOperatingUnit } from '../../../../types/operating-unit.types'
import {
  createOperatingUnit,
  deleteOperatingUnit,
} from '../../../../services/spectrum-manager.service'
import EditModal from '../../Modals/EditModal'
import { useToast } from '../../../../contexts/toast.context'
import { PlaybookContext } from '../../../../contexts/playbooks.context'

import styles from './styles.module.scss'

export const OperatingUnits = () => {
  const [isAdding, setIsAdding] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [operatingUnit, setOperatingUnit] = useState<IOperatingUnit | null>(
    null,
  )

  const { showToast } = useToast()
  const { operatingUnits, setOperatingUnits } = useContext(
    SpectrumManagerContext,
  )

  const { isLightMode } = useContext(PlaybookContext)

  const onClose = () => {
    setIsAdding(false)
  }

  const handleUnitChange = (key: string, value: string) => {
    const unit = operatingUnit ? operatingUnit : ({} as IOperatingUnit)

    setOperatingUnit({ ...unit, [key]: value })
  }

  const handleUnitCreate = async () => {
    if (operatingUnit) {
      const data = await createOperatingUnit(operatingUnit)

      if (data.data) {
        setOperatingUnits([data.data, ...operatingUnits])
        onClose()
      }
    }
  }

  const handleUpdate = async () => {
    if (operatingUnit) {
      const data = await createOperatingUnit(operatingUnit)

      if (data.data) {
        setOperatingUnits([data.data, ...operatingUnits])
        showToast('success', 'Unit created successfully')
      }
    }
  }

  const handleRemoveUnit = async (id: number) => {
    const data = await deleteOperatingUnit(id)

    if (data.success) {
      setOperatingUnits(operatingUnits.filter((unit) => unit.id !== id))
      showToast('success', 'Unit deleted successfully')
    }
  }

  return (
    <Box className={styles.operatingUnit}>
      <TableComponent
        isLightMode={isLightMode}
        data={operatingUnits}
        handleCreate={() => setIsAdding(true)}
        configs={OperatingUnitConfigs}
        handleRefetch={() => {}}
        handleDelete={handleRemoveUnit}
      />
      <Modal className={styles.modal} open={isAdding} onClose={onClose}>
        <Box className={styles.modalContent}>
          <Box className={styles.header}>
            <Typography data-testid="title" className={styles.title}>
              Add Unit
            </Typography>
            <IconButton data-testid="closeButton" onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={styles.content}>
            <Box className={styles.addUnit}>
              <Box className={styles.fieldContainer}>
                <Typography className={styles.label}>
                  Unit Name:
                  <Typography className={styles.asterisk}>*</Typography>:
                </Typography>
                <TextField
                  label="Unit Name"
                  className={styles.field}
                  value={operatingUnit?.unit_name}
                  onChange={(evt) =>
                    handleUnitChange('unit_name', evt.target.value)
                  }
                />
              </Box>
              <Box className={styles.fieldContainer}>
                <Typography className={styles.label}>
                  Manager<Typography className={styles.asterisk}>*</Typography>:
                </Typography>
                <TextField
                  label="Manager"
                  className={styles.field}
                  value={operatingUnit?.manager}
                  onChange={(evt) =>
                    handleUnitChange('manager', evt.target.value)
                  }
                />
              </Box>
              <Box className={styles.fieldContainer}>
                <Typography className={styles.label}>
                  Sub Unit Name
                  <Typography className={styles.asterisk}>*</Typography>:
                </Typography>
                <TextField
                  label="Sub unit name"
                  className={styles.field}
                  value={operatingUnit?.sub_unit_name}
                  onChange={(evt) =>
                    handleUnitChange('sub_unit_name', evt.target.value)
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box className={styles.actions}>
            <Button
              id="confirmButton"
              data-testid="confirmButton"
              className={styles.removeBtn}
              onClick={handleUnitCreate}
            >
              Create
            </Button>
            <Button
              className={styles.cancelBtn}
              color="inherit"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <EditModal
        open={isEditing}
        onClose={() => setIsEditing(false)}
        fields={OperatingUnitConfigs.items}
        handleEdit={handleUpdate}
        handleChange={() => {}}
      />
    </Box>
  )
}
