import { FC } from 'react'

interface IProps {
  size?: number
  onClick: () => void
}

const RemoveButton: FC<IProps> = ({ size = 24, onClick }) => {
  return (
    <div>
      <svg
        onClick={onClick}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
      >
        <g>
          <path
            d="M8 12H16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
            stroke="#000000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  )
}

export default RemoveButton
