import { IFrequencyAssignmentWithCoordinates } from '../../types/exercise.type'
import scss from './styles.module.scss'

interface IProps {
  point: IFrequencyAssignmentWithCoordinates
}

const PointDetails: React.FC<IProps> = ({ point }) => {
  return (
    <div className={scss.tooltip} >
      <p>Serial: {point.id_override}</p>
      <p>
        Request range: {point.exercise_request_frequency_single_start_freq}
        {point.exercise_request_frequency_single_end_indicator} -{' '}
        {point.exercise_request_frequency_single_end_freq}
        {point.exercise_request_frequency_single_end_indicator}
      </p>
      <p>
        Location: {point.exercise_request_location_latitude_deg}"
        {point.exercise_request_location_latitude_min}'
        {point.exercise_request_location_latitude_sec}{' '}
        {point.exercise_request_location_latitude_ns}{' '}
        {point.exercise_request_location_longitude_deg}"
        {point.exercise_request_location_longitude_min}'
        {point.exercise_request_location_longitude_sec}{' '}
        {point.exercise_request_location_longitude_ew}
      </p>
      <p>Radius: {point.exercise_request_location_radius}</p>
      <p>
        Transmitter Location:{' '}
        {point.exercise_request_location_transmitter_location}
      </p>
      <p>State: {point.state_region_type_name}</p>
      <p>511 Type: {point.major_function_511_name}</p>
      <p>
        Assigned Frequency: {point.exercise_request_assigned_frequency_value}
      </p>
      <p>
        Assigned Frequency Serial #:{' '}
        {point.exercise_request_assigned_frequency_list_serial_number}
      </p>
      <p>Emission Code: {point.f_114}</p>
      <p>Exercise: {point.exercise_name}</p>
      <p>Start Date: {point.exercise_start_date.split('T')[0]}</p>
      <p>End Date: {point.exercise_end_date.split('T')[0]}</p>
    </div>
  )
}

export default PointDetails
