import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { IFrequencyAssignmentWithCoordinates } from '../../../types/exercise.type'

interface IProps {
  point: IFrequencyAssignmentWithCoordinates
  isOpen: boolean
  onClose: () => void
  onUpdate: (updatedPoint: Partial<IFrequencyAssignmentWithCoordinates>) => void
}

const UpdateModal: FC<IProps> = ({ isOpen, onClose, point, onUpdate }) => {
  const [formData, setFormData] = useState<Partial<IFrequencyAssignmentWithCoordinates>>(
    {}
  )

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target

    setFormData((prev) => ({
      ...prev,
      [name]:
        isNaN(+value) ||
        typeof point[name as keyof IFrequencyAssignmentWithCoordinates] !== 'number'
          ? value
          : parseFloat(value),
    }))
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onUpdate(formData)
    onClose()
  }

  useEffect(() => {
    setFormData({})
  }, [isOpen])

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      hideBackdrop
    >
      <DialogTitle>Update Point</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            type="text"
            label="Exercise Name"
            name="exercise_name"
            value={formData.exercise_name ?? point.exercise_name}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            type="number"
            label="Location Radius"
            name="exercise_request_location_radius"
            value={
              formData.exercise_request_location_radius ??
              point.exercise_request_location_radius
            }
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            type="number"
            label="Frequency"
            name="exercise_request_assigned_frequency_value"
            value={
              formData.exercise_request_assigned_frequency_value ??
              point.exercise_request_assigned_frequency_value
            }
            onChange={handleChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={handleSubmit}
          color="primary"
          variant="contained"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateModal
