const convertToDecimal = (
  deg: number,
  min: number,
  sec: number,
  direction: string
) => {
  let decimal = deg + min / 60 + sec / 3600
  if (direction === 'S') {
    decimal *= -1
  }
  return decimal
}

export default convertToDecimal
