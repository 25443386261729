import { Slider } from '@mui/material'
import React, { useState } from 'react'

interface IPropa {
  min?: number
  max?: number
  values?: [number, number]
  onChange: (values: { min: number; max: number }) => void
}

const MultiRangeSlider: React.FC<IPropa> = ({
  min = 0,
  max = 10000,
  values,
  onChange,
}) => {
  const [value, setValue] = useState<number[]>([min, max])

  const handleChange = (_: Event, newValue: number | number[]) => {
    const [min, max] = newValue as number[]
    setValue(newValue as number[])
    onChange({ min, max })
  }

  return (
    <Slider
      value={values ?? value}
      onChange={handleChange}
      valueLabelDisplay="auto"
      min={min}
      max={max}
      step={(max - min) / 100}
      sx={{
        '& .MuiSlider-thumb': {
          backgroundColor: '#ced4da',
        },
        '& .MuiSlider-track': {
          backgroundColor: '#001eff',
        },
      }}
    />
  )
}

export default MultiRangeSlider
