import {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from 'react'
import {
  getAllDevices,
  getAllNomenclatures,
  getAllNumberOfStations,
} from '../services/equipment.service'
import {
  IDevice,
  INumberOfStations,
  IEquipmentNomenclature,
} from '../types/equipment.type'
import { IOperatingUnit } from '../types/operating-unit.types'
import { getAllOperatingUnits } from '../services/exercise-request-form.service'
import {
  IPlatform,
  IFrequencyPlan,
  ISpectrumManagerContext,
  ILocationTemplateWithRegions,
  IExerciseRequestDetails,
} from '../types/spectrum-manager.type'
import {
  getAllPlatforms,
  getAllDetailedExerciseRequests,
  getAllLocationTemplatesWithRegions,
  getAllFrequencyPlans,
} from '../services/spectrum-manager.service'
import { AuthContext } from './auth.context'

export const SpectrumManagerContext = createContext<ISpectrumManagerContext>({
  devices: [],
  platforms: [],
  setDevices: () => {},
  setPlatforms: () => {},
  nomenclatures: [],
  filterOptions: {},
  operatingUnits: [],
  numberOfStations: [],
  setFrequencyPlans: () => {},
  setFilterOptions: () => {},
  setOperatingUnits: () => {},
  detailedExerciseRequests: [],
  frequencyPlans: [],
  locationTemplatesWithRegions: [],
  setDetailedExerciseRequests: () => {},
  getDetailedExerciseRequests: () => {},
  setLocationTemplatesWithRegions: () => {},
})

export const SpectrumManagerProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [devices, setDevices] = useState<IDevice[]>([])
  const [platforms, setPlatforms] = useState<IPlatform[]>([])
  const [operatingUnits, setOperatingUnits] = useState<IOperatingUnit[]>([])
  const [filterOptions, setFilterOptions] = useState<{
    locationId?: number
    status?: string
    exerciseId?: number
    search?: string
  }>({})
  const [nomenclatures, setNomenclatures] = useState<IEquipmentNomenclature[]>(
    [],
  )
  const [numberOfStations, setNumberOfStations] = useState<INumberOfStations[]>(
    [],
  )
  const [detailedExerciseRequests, setDetailedExerciseRequests] = useState<
    IExerciseRequestDetails[]
  >([])
  const [frequencyPlans, setFrequencyPlans] = useState<IFrequencyPlan[]>([])
  const [locationTemplatesWithRegions, setLocationTemplatesWithRegions] =
    useState<ILocationTemplateWithRegions[]>([])

  const abortControllerRef = useRef<AbortController | null>(null)

  const memoizedFilterOptions = useMemo(() => filterOptions, [filterOptions])

  const { user } = useContext(AuthContext)

  const getDevices = async () => {
    const data = await getAllDevices()

    if (data.success && data.data) {
      setDevices(data.data)
    }
  }

  const getNumberOfStations = async () => {
    const data = await getAllNumberOfStations()

    if (data.success && data.data) {
      setNumberOfStations(data.data)
    }
  }

  const getNomenclatures = async () => {
    const data = await getAllNomenclatures()

    if (data.success && data.data) {
      setNomenclatures(data.data)
    }
  }

  const getOperatingUnits = async () => {
    const data = await getAllOperatingUnits()

    if (data.success && data.data) {
      setOperatingUnits(data.data)
    }
  }

  const getLocationTemplatesWithRegions = async () => {
    const data = await getAllLocationTemplatesWithRegions()

    if (data.success && data.data) {
      setLocationTemplatesWithRegions(data.data)
    }
  }

  const getPlatforms = async () => {
    const data = await getAllPlatforms()

    if (data.success && data.data) {
      setPlatforms(data.data)
    }
  }

  const getDetailedExerciseRequests = async (userId: number | null) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }

    const controller = new AbortController()
    abortControllerRef.current = controller

    const data = await getAllDetailedExerciseRequests(
      filterOptions,
      controller.signal,
    )

    if (data.success && data.data) {
      setDetailedExerciseRequests(data.data)
    }
  }

  const getFrequencyPlans = async () => {
    const data = await getAllFrequencyPlans(filterOptions)

    if (data.data) {
      setFrequencyPlans(data.data)
    }
  }

  useEffect(() => {
    if (!user) return

    getDevices()
    getPlatforms()
    getNomenclatures()
    getOperatingUnits()
    getNumberOfStations()
    getLocationTemplatesWithRegions()
  }, [user])

  useEffect(() => {
    if (!user) return

    getFrequencyPlans()
  }, [user, memoizedFilterOptions])

  return (
    <SpectrumManagerContext.Provider
      value={{
        devices,
        setDevices,
        setPlatforms,
        platforms,
        nomenclatures,
        filterOptions,
        operatingUnits,
        frequencyPlans,
        numberOfStations,
        setFilterOptions,
        setFrequencyPlans,
        setOperatingUnits,
        detailedExerciseRequests,
        getDetailedExerciseRequests,
        setDetailedExerciseRequests,
        locationTemplatesWithRegions,
        setLocationTemplatesWithRegions,
      }}
    >
      {children}
    </SpectrumManagerContext.Provider>
  )
}
