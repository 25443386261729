import { Dispatch, FC, SetStateAction, useContext } from 'react'
import { Box } from '@mui/material'
import GeneralSection from '../GeneralSection'
import LocationSection from '../LocationSection'
import FrequencySection from '../FrequencySection'
import EquipmentSection from '../Equipment'
import { ExerciseRequestContext } from '../../../../contexts/exercise-request.context'
import { TabPanel } from '../../../shared/TabPanel'
import Toolbar from '../../SpectrumManagerDashboard/Toolbar'
import { ExerciseRequestsFormTabs } from '../../../../utils/constants'

import styles from './styles.module.scss'

interface IProps {
  value: number
  setValue: Dispatch<SetStateAction<number>>
  isLightMode: boolean
}

const Sections: FC<IProps> = ({ value, setValue, isLightMode }) => {
  const { selectedExerciseRequest } = useContext(ExerciseRequestContext)

  return (
    <Box className={`${styles.sections} sub-container`}>
      {selectedExerciseRequest && (
        <>
          <Toolbar
            value={value}
            setValue={setValue}
            tabs={ExerciseRequestsFormTabs}
          />
          <Box className={styles.tabsContainer}>
            <TabPanel value={value} index={0}>
              <Box sx={{ overflowY: 'hidden', overflowX: '' }}>
                <GeneralSection
                  isLightMode={isLightMode}
                  setValue={setValue}
                  selectedExerciseRequest={selectedExerciseRequest}
                />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <LocationSection isLightMode={isLightMode} setValue={setValue} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <FrequencySection isLightMode={isLightMode} setValue={setValue} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <EquipmentSection isLightMode={isLightMode} setValue={setValue} />
            </TabPanel>
          </Box>
        </>
      )}
    </Box>
  )
}

export default Sections
