import {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import dayjs from 'dayjs'
import {
  Box,
  Modal,
  Button,
  TextField,
  Typography,
  IconButton,
  Tabs,
  Tab,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { TabPanel } from '../../../shared/TabPanel'
import { DeviceAntennas } from '../../SpectrumManagerDashboard/DeviceAntennas'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { DevicesTransmitters } from '../../SpectrumManagerDashboard/DeviceTransmitters'
import { getALlDeviceAntennas } from '../../../../services/equipment.service'
import { IDevice, IDeviceAntenna } from '../../../../types/equipment.type'
import { getAllDeviceTransmitter } from '../../../../services/spectrum-manager.service'
import { IDeviceTransmitter } from '../../../../types/spectrum-manager.type'

import styles from './styles.module.scss'

interface IProps<T> {
  open: boolean
  fields: { key: string; column: string; isTag: boolean }[]
  device: IDevice
  isCreating: boolean
  onClose: () => void
  handleSave: () => void
  handleChange: (
    key: string,
    value: string | number | null | dayjs.Dayjs,
  ) => void
}

const DeviceModal = <T extends Record<string, any>>({
  open,
  fields,
  device,
  onClose,
  isCreating,
  handleSave,
  handleChange,
}: IProps<T>) => {
  const [value, setValue] = useState(0)
  const [deviceAntennas, setDeviceAntennas] = useState<IDeviceAntenna[]>([])
  const [deviceTransmitters, setDeviceTransmitters] = useState<
    IDeviceTransmitter[]
  >([])
  const { isLightMode } = useContext(PlaybookContext)

  const onTabChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue)
    getDeviceAntennas()
    getDeviceTransmitters()
  }

  const handleClose = () => {
    setDeviceAntennas([])
    setDeviceTransmitters([])
    onClose()
  }

  const getDeviceAntennas = useCallback(async () => {
    if (device) {
      const data = await getALlDeviceAntennas(device.id)

      if (data.data) {
        setDeviceAntennas(data.data)
      }
    }
  }, [device.id])

  const getDeviceTransmitters = useCallback(async () => {
    if (device) {
      const data = await getAllDeviceTransmitter(device.id)

      if (data.data) {
        setDeviceTransmitters(data.data)
      }
    }
  }, [device.id])

  useEffect(() => {
    getDeviceAntennas()
    getDeviceTransmitters()
  }, [device.id])

  return (
    <Modal className={styles.modal} open={open} onClose={handleClose}>
      <Box className={styles.modalContent}>
        <Box className={styles.content}>
          <Box className={styles.header}>
            <Typography className={styles.title} id="title">
              Device Details
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={styles.content}>
            <Box className={styles.fields}>
              {fields.slice(1, 5).map((field) => (
                <Box className={styles.fieldContainer} key={field.key}>
                  <Typography>{field.column}</Typography>
                  <TextField
                    value={(device as any)[field.key]}
                    className={styles.field}
                    size="small"
                    onChange={(evt) =>
                      handleChange(field.key, evt.target.value)
                    }
                  />
                </Box>
              ))}
            </Box>
            <Box className={styles.fields}>
              {fields.slice(5, fields.length - 1).map((field) => (
                <Box className={styles.fieldContainer} key={field.key}>
                  <Typography>{field.column}</Typography>
                  <TextField
                    value={(device as any)[field.key]}
                    size="small"
                    className={styles.field}
                    onChange={(evt) =>
                      handleChange(field.key, evt.target.value)
                    }
                  />
                </Box>
              ))}
              <Box className={styles.fieldContainer}>
                <Typography>Last Updated</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{ textField: { size: 'small' } }}
                    label="Last Updated"
                    value={dayjs(device.last_updated)}
                    className={styles.field}
                    onChange={(newValue) =>
                      handleChange('last_updated', newValue)
                    }
                  />
                </LocalizationProvider>
              </Box>
              <Box className={styles.fieldContainer}>
                <Typography></Typography>
                <Button variant="outlined" onClick={handleSave}>
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
          <Tabs
            orientation="horizontal"
            value={value}
            onChange={onTabChange}
            TabIndicatorProps={{
              sx: {
                backgroundColor: isLightMode ? 'rgb(22, 119, 56)' : 'white',
              },
            }}
            sx={{
              color: isLightMode ? 'green' : 'white',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Tab label="Device Transmitters" />
            <Tab label="Device Antennas" />
          </Tabs>
          <Box
            sx={{
              pointerEvents: isCreating ? 'none' : 'auto',
              opacity: isCreating ? 0.5 : 1,
            }}
          >
            <TabPanel value={value} index={0}>
              <DevicesTransmitters
                deviceTransmitters={deviceTransmitters}
                deviceId={device.id}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <DeviceAntennas
                deviceAntennas={deviceAntennas}
                deviceId={device.id}
              />
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default DeviceModal
