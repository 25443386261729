import { FC, useState } from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Box, Modal, Button, Typography, IconButton } from '@mui/material'
import FileProcessor from './import'
import { useToast } from '../../../../contexts/toast.context'
import { importSfaf } from '../../../../services/spectrum-manager.service'

import styles from './styles.module.scss'

interface FieldData {
  [key: string]: string
}

interface IProps {
  isOpen: boolean
  isLightMode: boolean
  onClose: () => void
}

const ImportSfafModal: FC<IProps> = ({ isLightMode, isOpen, onClose }) => {
  const [parsedData, setParsedData] = useState<FieldData[]>([])

  const { showToast } = useToast()

  const handleUpload = async () => {
    const data = await importSfaf(parsedData)

    if (data.success) {
      showToast('success', 'SFAF Uploaded successfully')
    } else {
      showToast('error', 'SFAF upload failed')
    }
  }

  return (
    <Modal className={styles.modal} open={isOpen} onClose={onClose}>
      <Box className={styles.modalContent}>
        <Box>
          <Box className={styles.header}>
            <Typography
              data-testid="title"
              id="enableTitle"
              className={styles.title}
            >
              Import SFAF
            </Typography>
            <IconButton data-testid="closeButton" onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={styles.content}>
            <FileProcessor
              isLightMode={isLightMode}
              setParsedData={(parsedData) => setParsedData(parsedData)}
            />
          </Box>
          <Box className={styles.actions}>
            <Button
              className={styles.addBtn}
              variant="outlined"
              onClick={handleUpload}
            >
              Upload
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ImportSfafModal
