import { useContext, useEffect, useState } from 'react'
import {
  type Node,
  type Edge,
  useNodesInitialized,
  useStore,
  useReactFlow,
} from '@xyflow/react'
import { useControls } from 'leva'
import {
  getSourceHandlePosition,
  getTargetHandlePosition,
} from '../utils/functions'
import layoutAlgorithms, { type LayoutAlgorithmOptions } from '../algorithms'
import { PlaybookContext } from '../contexts/playbooks.context'
import { updatePlaybook } from '../services/playbook.service'
import { IAutoLayout } from '../types/playbook.type'

export type LayoutOptions = {
  algorithm: keyof typeof layoutAlgorithms
} & LayoutAlgorithmOptions

type Elements = {
  nodeMap: Map<string, Node>
  edgeMap: Map<string, Edge>
}

function compareElements(xs: Elements, ys: Elements) {
  return (
    compareNodes(xs.nodeMap, ys.nodeMap) && compareEdges(xs.edgeMap, ys.edgeMap)
  )
}

function compareNodes(xs: Map<string, Node>, ys: Map<string, Node>) {
  if (xs.size !== ys.size) return false

  for (const [id, x] of xs.entries()) {
    const y = ys.get(id)

    if (!y) return false

    if (x.resizing || x.dragging) return true
    if (x.width !== y.width || x.height !== y.height) return false
  }

  return true
}

function compareEdges(xs: Map<string, Edge>, ys: Map<string, Edge>) {
  if (xs.size !== ys.size) return false

  for (const [id, x] of xs.entries()) {
    const y = ys.get(id)

    if (!y) return false
    if (x.source !== y.source || x.target !== y.target) return false
    if (x?.sourceHandle !== y?.sourceHandle) return false
    if (x?.targetHandle !== y?.targetHandle) return false
  }

  return true
}

function useAutoLayout(isLayoutEditing: boolean) {
  const nodesInitialized = useNodesInitialized()

  const [debouncedValue, setDebouncedValue] = useState<IAutoLayout | null>(null)

  const { setNodes, setEdges } = useReactFlow()
  const { playbook, layoutOptions, setPlaybook, setLayoutOptions } =
    useContext(PlaybookContext)

  const options = useControls({
    about: {
      value: 'Automatically arrange your nodes and edges',
      editable: false,
    },
    algorithm: {
      value: layoutOptions?.algorithm as LayoutOptions['algorithm'],
      options: ['dagre', 'd3-hierarchy', 'elk'] as LayoutOptions['algorithm'][],
    },
    direction: {
      value: layoutOptions?.direction as LayoutOptions['direction'],
      options: {
        down: 'TB',
        right: 'LR',
        up: 'BT',
        left: 'RL',
      } as Record<string, LayoutOptions['direction']>,
    },
  })

  const elements = useStore(
    (state) => ({
      nodeMap: state.nodeLookup,
      edgeMap: state.edgeLookup,
    }),
    compareElements,
  )

  const debouncedUpdate = async () => {
    if (debouncedValue && playbook) {
      updatePlaybook(playbook)
    }
  }

  useEffect(() => {
    if (
      !nodesInitialized ||
      elements.nodeMap.size === 0 ||
      !isLayoutEditing ||
      !layoutOptions
    ) {
      return
    }

    const runLayout = async () => {
      //@ts-ignore

      const layoutAlgorithm = layoutAlgorithms[options.algorithm]
      const nodes = [...elements.nodeMap.values()]
      const edges = [...elements.edgeMap.values()]

      const { nodes: nextNodes, edges: nextEdges } = await layoutAlgorithm(
        nodes,
        edges,
        options,
      )

      for (const node of nextNodes) {
        node.style = { ...node.style, opacity: 1 }
        node.sourcePosition = getSourceHandlePosition(options.direction)
        node.targetPosition = getTargetHandlePosition(options.direction)
      }

      for (const edge of edges) {
        edge.style = { ...edge.style, opacity: 1 }
      }

      setNodes(nextNodes)
      setEdges(nextEdges)

      if (playbook) {
        const updatedPlaybook = {
          ...playbook,
          nodes: nextNodes,
          edges: nextEdges as Array<Edge & { label?: string; data: any }>,
          autoLayoutOptions: {
            direction: options.direction,
            algorithm: options.algorithm,
          },
        }

        setPlaybook({
          ...updatedPlaybook,
        })
        setLayoutOptions({ ...updatedPlaybook.autoLayoutOptions })
      }
    }

    runLayout()
  }, [nodesInitialized, elements, options, setNodes, setEdges])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(options)
    }, 1000)

    return () => {
      clearTimeout(handler)
    }
  }, [options])

  useEffect(() => {
    debouncedUpdate()
  }, [debouncedValue])
}

export default useAutoLayout
