import { createContext, ReactNode, useState } from 'react'
import { getAllSchedulers } from '../services/scheduler.service'
import { IScheduler, ISchedulerContext } from '../types/scheduler.type'

export const SchedulerContext = createContext<ISchedulerContext>({
  schedulers: [],
  getSchedulers: (id: number) => {},
  setSchedulers: () => {},
})

export const SchedulersProvider = ({ children }: { children: ReactNode }) => {
  const [schedulers, setSchedulers] = useState<IScheduler[]>([])

  const getSchedulers = async (playbookId: number) => {
    const data = await getAllSchedulers(playbookId)

    if (data.data) {
      setSchedulers(data.data)
    }
  }

  return (
    <SchedulerContext.Provider
      value={{ schedulers, setSchedulers, getSchedulers }}
    >
      {children}
    </SchedulerContext.Provider>
  )
}
