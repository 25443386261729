import { FC, useContext, useState } from 'react'
import {
  Box,
  Typography,
  IconButton,
  Button,
  Modal,
  TextField,
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useToast } from '../../../../contexts/toast.context'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { updatePlaybook } from '../../../../services/playbook.service'
import { IPlaybook } from '../../../../types/playbook.type'

import styles from './styles.module.scss'

interface IProps {
  playbook: IPlaybook
  onClose: () => void
}

const PlaybookEditModal: FC<IProps> = ({ playbook, onClose }) => {
  const [name, setName] = useState(playbook.name)

  const { showToast } = useToast()

  const { getPlaybooks } = useContext(PlaybookContext)

  const handleEdit = async () => {
    const data = await updatePlaybook({
      ...playbook,
      name,
    })

    if (data.success) {
      getPlaybooks()
      showToast('success', data.message)
      onClose()
    }
  }

  return (
    <Modal className={styles.modal} open onClose={onClose}>
      <Box className={styles.modalContent}>
        <Box>
          <Box className={styles.header}>
            <Typography data-testid="title" className={styles.title}>
              Edit Playbook: {playbook.name}
            </Typography>
            <IconButton data-testid="closeButton" onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={styles.selects}>
            <TextField
              label="Name"
              value={name}
              onChange={(evt) => setName(evt.target.value)}
            />
          </Box>
          <Box className={styles.actions}>
            <Button
              className={styles.removeBtn}
              color="success"
              onClick={handleEdit}
            >
              Edit
            </Button>
            <Button
              className={styles.cancelBtn}
              color="inherit"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default PlaybookEditModal
