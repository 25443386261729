import { Autocomplete, TextField } from '@mui/material'
import { FC, SyntheticEvent, useEffect, useState } from 'react'

interface IProps {
  values?: { min: number; max: number }
  onChange: (value: { min: number; max: number }) => void
}

interface IOption {
  label: string
  range: { min: number; max: number }
}

const options: IOption[] = [
  { label: 'MF (0-0.3 MHz)', range: { min: 0, max: 0.3 } },
  { label: 'HF (3-30 MHz)', range: { min: 3, max: 30 } },
  { label: 'VHF|A (30-250 MHz)', range: { min: 30, max: 250 } },
  { label: 'VHF|B (250-300 MHz)', range: { min: 250, max: 300 } },
  { label: 'UHF|B (300-500 MHz)', range: { min: 300, max: 500 } },
  { label: 'UHF|C (500-1000 MHz)', range: { min: 500, max: 1000 } },
  { label: 'UHF|L-Band|D (1-2 GHz)', range: { min: 1000, max: 2000 } },
  { label: 'UHF|S-Band|E (2-3 GHz)', range: { min: 2000, max: 3000 } },
  { label: 'SHF|S-Band|F (3-4 GHz)', range: { min: 3000, max: 4000 } },
  { label: 'SHF|C-Band|G (4-6 GHz)', range: { min: 4000, max: 6000 } },
  { label: 'SHF|C-Band|H (6-8 GHz)', range: { min: 6000, max: 8000 } },
  { label: 'SHF|X-Band|I (8-10 GHz)', range: { min: 8000, max: 10000 } },
]

const FrequencyPicker: FC<IProps> = ({ values, onChange }) => {
  const [value, setValue] = useState<IOption | null>(null)

  useEffect(() => {
    if (!values) return
    const matchedOption = options.find(
      (option) =>
        values.min >= option.range.min && values.max <= option.range.max
    )

    setValue(matchedOption || null)
  }, [values])

  const handleChange = (
    _: SyntheticEvent<Element, Event>,
    newValue: IOption | null
  ) => {
    onChange(newValue?.range ?? { min: 0, max: 10000 })
    setValue(newValue)
  }

  return (
    <Autocomplete
      fullWidth
      value={value}
      options={options}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField {...params} label="Select Frequency Band" />
      )}
      onChange={handleChange}
    />
  )
}

export default FrequencyPicker
