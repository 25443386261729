import { ReactFlow, Controls, Node } from '@xyflow/react'
import styled from 'styled-components'
import SendIcon from '@mui/icons-material/Send'
import TextNode from '../customNodes/TextNode'
import EmailIcon from '@mui/icons-material/Email'
import PlaceIcon from '@mui/icons-material/PlaceOutlined'
import SchemaIcon from '@mui/icons-material/SchemaOutlined'
import StartNode from '../customNodes/StartNode'
import ButtonsNode from '../customNodes/ButtonsNode'
import LayersIcon from '@mui/icons-material/LayersOutlined'
import Grid3x3Icon from '@mui/icons-material/Grid3x3'
import GamepadIcon from '@mui/icons-material/Gamepad'
import SubjectIcon from '@mui/icons-material/Subject'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import DevicesIcon from '@mui/icons-material/DevicesOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import DonutSmallIcon from '@mui/icons-material/DonutSmall'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import ControlCameraIcon from '@mui/icons-material/ControlCamera'
import SignalWifi3BarIcon from '@mui/icons-material/SignalWifi3Bar'
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayListOutlined'
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar'
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey'
import { PlaybookColumnsTypes } from '../types/playbook.type'
import AppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined'
import { AddNodeTypes, NodeTypes } from '../types/node.type'
import SignalCellularAlt3BarOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined'

export const HeaderLinks = [
  {
    title: 'Frequency Request Form',
    url: '/frequency-request-form',
  },
  {
    title: 'Spectrum Manager Dashboard',
    url: '/spectrum-manager-dashboard',
    isRestricted: true,
  },
  {
    title: 'Playbooks',
    url: '/playbooks',
    isRestricted: true,
  },
  {
    title: 'Map',
    url: '/map',
    isRestricted: true,
  },
]

export const PlaybookColumns = [
  PlaybookColumnsTypes.NAME,
  PlaybookColumnsTypes.ENABLED,
  PlaybookColumnsTypes.CREATED_AT,
]

export const NewNodes = [
  {
    type: AddNodeTypes.MAP,
    name: 'Geo-Based Trigger',
    description: '',
    icon: LocationOnIcon,
  },
  {
    type: AddNodeTypes.SLIDER,
    name: 'Interference Trigger',
    description: 'Pull sensor network data into Playbook',
    icon: DonutSmallIcon,
  },
  {
    type: AddNodeTypes.TurnOffNetwork,
    name: AddNodeTypes.TurnOffNetwork,
    description: '',
    icon: SignalWifi3BarIcon,
  },
  {
    type: AddNodeTypes.ModifyBandwidth,
    name: AddNodeTypes.ModifyBandwidth,
    description: '',
    icon: SignalCellularAlt3BarOutlinedIcon,
  },
  {
    type: AddNodeTypes.ModifyQualityOfService,
    name: AddNodeTypes.ModifyQualityOfService,
    description: '',
    icon: SettingsIcon,
  },
  {
    type: AddNodeTypes.SwitchFrequency,
    name: AddNodeTypes.SwitchFrequency,
    description: '',
    icon: SignalCellular3BarIcon,
  },
  {
    type: AddNodeTypes.SFAF,
    name: 'SFAF Generator',
    description: '',
    icon: SubjectIcon,
  },
  {
    type: AddNodeTypes.BUTTONS,
    name: 'Manual Review',
    description: '',
    icon: GamepadIcon,
  },
  {
    type: AddNodeTypes.REQUEST_ID,
    name: 'Comm Plan',
    description: 'Filter frequency requests from Communication Plan',
    icon: Grid3x3Icon,
  },
  {
    type: AddNodeTypes.POST_REQUEST,
    name: 'Post Request',
    description: 'Send post request',
    icon: SendIcon,
  },
  {
    type: AddNodeTypes.EMAIL,
    name: 'Email Notification',
    description: 'Send an email notification of Playbook status',
    icon: EmailIcon,
  },
]

export const NewNodeLabels = {
  [AddNodeTypes.ACTION]: 'Choose an Action',
  [AddNodeTypes.EMAIL]: 'Add an email',
  [AddNodeTypes.REQUEST_ID]: 'Choose a request id',
  [AddNodeTypes.TRIGGER]: 'Choose a Trigger',
  [AddNodeTypes.SFAF]: 'Choose a Trigger',
  [AddNodeTypes.SLIDER]: 'Choose a Trigger',
  [AddNodeTypes.MAP]: 'Choose a Trigger',
  [AddNodeTypes.BUTTONS]: 'Choose a Trigger',
}

export const initialPagination = {
  count: 0,
  skip: 0,
  take: 10,
}

export const initialFilter = {
  column: '',
  values: [],
  logicalOperation: '',
  selectedValues: [],
  chosenValue: '',
}

export const ReactFlowStyled = styled(ReactFlow)`
  background-color: ${(props) =>
    props.theme.bg === '#fff' ? props.theme.bg : '#04111a'};
`

export const ControlsStyled = styled(Controls)`
  button {
    background-color: ${(props) => props.theme.controlsBg};
    color: ${(props) => props.theme.controlsColor};
    border-bottom: 1px solid ${(props) => props.theme.controlsBorder};

    &:hover {
      background-color: ${(props) => props.theme.controlsBgHover};
    }

    path {
      fill: currentColor;
    }
  }
`

export const nodeTypes = {
  map: TextNode,
  sfaf: TextNode,
  text: StartNode,
  email: TextNode,
  slider: TextNode,
  action: TextNode,
  buttons: ButtonsNode,
  requestId: TextNode,
  postRequest: TextNode,
  mapExample: TextNode,
  sfafExample: TextNode,
  textExample: StartNode,
  emailExample: TextNode,
  sliderExample: TextNode,
  actionExample: TextNode,
  buttonsExample: ButtonsNode,
  requestIdExample: TextNode,
  postRequestExample: TextNode,
}

export const NodeLabels = {
  [NodeTypes.TEXT]: 'START',
  [NodeTypes.MAP]: 'Geo-Based',
  [NodeTypes.SFAF]: 'SFAF Generator',
  [NodeTypes.EMAIL]: 'Email Notification',
  [NodeTypes.SLIDER]: 'Interference',
  [NodeTypes.BUTTONS]: 'Manual Review',
  [NodeTypes.PLAYBOOK]: 'Playbook',
  [NodeTypes.REQUEST_ID]: 'Comma Plan',
  [NodeTypes.POST_REQUEST]: 'Post Request',
  [NodeTypes.TurnOffNetwork]: 'Turn Off Network',
  [NodeTypes.SwitchFrequency]: 'Switch Frequency',
  [NodeTypes.ModifyBandwidth]: 'Modify Bandwidth',
  [NodeTypes.ModifyQualityOfService]: 'Modify Quality of Service',
}

export const nodeColor = (node: Node) => {
  switch (node.type) {
    case 'map':
      return '#6ede87'
    case 'text':
      return '#6A686A'
    case 'slider':
      return '#ff0072'
    case 'mapInputs':
      return '#F07427'
    case 'interference':
      return '#277BC7'
    default:
      return '#ff0072'
  }
}

export const ExerciseRequestTableConfigs = {
  title: 'Requests',
  isViewable: true,
  isDeletable: false,
  isEditable: false,
  isSearchable: false,
  isSelectable: true,
  items: [
    { key: 'id', column: 'ID', isTag: false },
    { key: 'exercise_request_name', column: 'Request Name', isTag: false },
    { key: 'username', column: 'Username', isTag: true },
    {
      key: 'number_of_frequencies',
      width: 100,
      column: 'Quantity',
      editable: true,
      isTag: false,
    },
    { key: 'site_location', column: 'Site location', isTag: true },
    { key: 'equipment', column: 'Equipment', isTag: false },
    { key: 'unit', column: 'Unit', isTag: true },
    { key: 'radius_306', column: 'Radius 306', editable: true, isTag: true },
    { key: 'radius_406', column: 'Radius 406', editable: true, isTag: true },
    { key: 'created_at', column: 'Created at', isDate: true, isTag: false },
    { key: 'exercise_name', column: 'Exercise', isTag: false },
    { key: 'frequency_id', column: 'Frequency Id', isTag: false },
    { key: 'user_phone', column: 'User Phone', isTag: false },
    { key: 'classification', column: 'Classification', isTag: false },
    { key: 'lead_time', column: 'Lead time', isDate: true, isTag: false },
    { key: 'start_date', column: 'Start time', isDate: true, isTag: false },
    { key: 'end_date', column: 'End time', isDate: true, isTag: false },
    { key: 'coordinates', column: 'Coordinates', isTag: false },
    { key: 'frequency_start', column: 'Frequency start', isTag: false },
    { key: 'frequency_end', column: 'Frequency end', isTag: false },
    { key: 'status', column: 'Status', isTag: true },
    { key: 'frequency', column: 'Frequencies', isTag: false },
    { key: 'sub_unit', column: 'Sub unit', isTag: true },
    { key: 'receiving_unit', column: 'Receiving unit', isTag: true },
    { key: 'request_id', column: 'Request Id', isTag: false },
    { key: 'platform', column: 'Platform', isTag: false },
  ],
}

export const FrequencyPlansTableConfigs = {
  title: 'Open Frequency Requests',
  isViewable: false,
  isDeletable: false,
  isEditable: false,
  isSearchable: true,
  isSelectable: true,
  items: [
    { key: 'id', column: 'ID', isTag: false },
    { key: 'start_freq', column: 'Start Freq', isTag: false },
    { key: 'end_freq', column: 'End Freq', isTag: false },
    { key: 'f_114', column: 'F 114', isTag: false },
    { key: 'power', column: 'Power', isTag: false },
    { key: 'classification', column: 'Classification', isTag: false },
    { key: 'start_date', column: 'Start time', isDate: true, isTag: false },
    { key: 'end_date', column: 'End time', isDate: true, isTag: false },
    { key: 'site_location', column: 'Site location', isTag: true },
    { key: 'radius_306', column: 'Radius 306', isTag: true },
    { key: 'radius_406', column: 'Radius 406', isTag: true },
    { key: 'frequency', column: 'Frequencies', isTag: false },
    { key: 'unit', column: 'Unit', isTag: true },
    { key: 'sub_unit', column: 'Sub unit', isTag: true },
    { key: 'receiving_unit', column: 'Receiving unit', isTag: true },
    {
      key: 'exercise_request_name',
      column: 'Exercise Request Name',
      isTag: false,
    },
    { key: 'platform', column: 'Platform', isTag: false },
    { key: 'exercise_name', column: 'Exercise Name', isTag: false },
    { key: 'state', column: 'State', isTag: true },
    { key: 'lat_long', column: 'Lat Long', isTag: false },
  ],
}

export const lightModeTableColors = [
  {
    background: '#FFCACA',
    color: '#940304',
  },
  {
    background: '#A7F3D0',
    color: '#23734E',
  },
  {
    background: '#C8D2FF',
    color: '#2438A2',
  },
  {
    background: '#FDE68B',
    color: '#544301',
  },
  {
    background: '#FCD7BB',
    color: '#8D491B',
  },
  {
    background: '#BFDCFE',
    color: '#023E89',
  },
  {
    background: '#EECFF3',
    color: '#671D73',
  },
]

export const darkModeTableColors = [
  {
    background: '#FFCACA',
    color: '#940304',
  },
  {
    background: '#A7F3D0',
    color: '#23734E',
  },
  {
    background: '#C8D2FF',
    color: '#2438A2',
  },
  {
    background: '#FDE68B',
    color: '#544301',
  },
  {
    background: '#FCD7BB',
    color: '#8D491B',
  },
  {
    background: '#BFDCFE',
    color: '#023E89',
  },
  {
    background: '#EECFF3',
    color: '#671D73',
  },
]

export const LocationTemplateConfigs = {
  title: 'Location',
  isViewable: false,
  isDeletable: true,
  isEditable: true,
  items: [
    { key: 'id', column: 'ID', isTag: false },
    { key: 'template_name', column: 'Template Name', isTag: false },
    { key: 'code', column: 'Region Code(300)', isTag: true },
    { key: 'name', column: 'State Region Name', isTag: true },
    {
      key: 'transmitter_location',
      column: 'Transmitter Location(301)',
      isTag: false,
    },
    { key: 'longitude_deg', column: 'longitude Deg', isTag: false },
    { key: 'longitude_min', column: 'Longitude Min', isTag: false },
    { key: 'longitude_sec', column: 'Longitude Sec', isTag: false },
    { key: 'longitude_ew', column: 'Longitude ew', isTag: true },
    { key: 'latitude_deg', column: 'Latitude Deg', isTag: false },
    { key: 'latitude_min', column: 'Latitude Min', isTag: false },
    { key: 'latitude_sec', column: 'Latitude Sec', isTag: false },
    { key: 'latitude_ns', column: 'Latitude ns', isTag: true },
    { key: 'afc', column: 'Afc', isTag: true },
    { key: 'afc_code', column: 'Afc code', isTag: false },
    { key: 'afc_coord_note', column: 'Afc coord note', isTag: false },
  ],
}

export const AddTemplateItems = [
  { key: 'template_name', column: 'Template Name' },
  { key: 'longitude_deg', column: 'longitude Deg' },
  { key: 'longitude_min', column: 'Longitude Min' },
  { key: 'longitude_sec', column: 'Longitude Sec' },
  { key: 'longitude_ew', column: 'Longitude ew' },
  { key: 'latitude_deg', column: 'Latitude Deg' },
  { key: 'latitude_min', column: 'Latitude Min' },
  { key: 'latitude_sec', column: 'Latitude Sec' },
  { key: 'latitude_ns', column: 'Latitude ns' },
  { key: 'state_region_type_id', column: 'Region' },
  {
    key: 'transmitter_location',
    column: 'Transmitter Location',
  },
  { key: 'afc', column: 'Afc' },
  { key: 'afc_code', column: 'Afc code' },
  { key: 'afc_coord_note', column: 'Afc coord note' },
]

export const OperatingUnitConfigs = {
  title: 'Operating Units',
  isViewable: false,
  isDeletable: true,
  isEditable: false,
  items: [
    { key: 'id', column: 'ID', width: 140, isTag: false },
    {
      key: 'created_at',
      column: 'Created at',
      width: 300,
      isDate: true,
      isTag: false,
    },
    { key: 'manager', column: 'Manager', width: 300, isTag: true },
    {
      key: 'unit_name',
      width: 300,
      column: 'Unit Name',
      isTag: false,
    },
    {
      key: 'sub_unit_name',
      width: 300,
      column: 'Sub Unit Name',
      isTag: false,
    },
  ],
}

export const ExercisesConfigs = {
  title: 'Exercises',
  isViewable: false,
  isDeletable: true,
  isEditable: false,
  items: [
    { key: 'id', column: 'ID', width: 250, isTag: false },
    { key: 'name', column: 'Name', width: 250, isTag: false },
    {
      key: 'start_date',
      column: 'Start Time',
      width: 250,
      isDate: true,
      isTag: false,
    },
    {
      key: 'end_date',
      column: 'End Time',
      width: 250,
      isDate: true,
      isTag: false,
    },
    { key: 'f_702', column: 'F 702', width: 250, isTag: false },
    { key: 'f_910', column: 'F 910', width: 250, isTag: false },
  ],
}

export const DevicesConfigs = {
  title: 'Devices',
  isViewable: true,
  isDeletable: true,
  isEditable: false,
  items: [
    { key: 'id', column: 'ID', isTag: false, width: 70 },
    { key: 'model', column: 'Model', isTag: false },
    { key: 'certification', column: 'Certification', isTag: false },
    { key: 'irac', column: 'Irac', isTag: false },
    { key: 'sps', column: 'SPS', isTag: false },
    { key: 'code', column: 'Code', isTag: false },
    { key: 'gcu', column: 'GCU', isTag: false },
    { key: 'last_updated', column: 'Last Updated', isTag: false },
  ],
}

export const PlatformsConfigs = {
  title: 'Platforms',
  isViewable: true,
  isDeletable: true,
  isEditable: false,
  items: [
    { key: 'id', column: 'ID', isTag: false, width: 70 },
    { key: 'platform', column: 'Platform', isTag: false, width: 300 },
    { key: 'name', column: 'Name', isTag: false, width: 300 },
    { key: 'max_altitude', column: 'Max Altitude', isTag: false, width: 300 },
    { key: 'station', column: 'Station', isTag: true, width: 300 },
  ],
}

export const DeviceTransmitterConfigs = {
  title: 'Device Transmitters',
  isViewable: false,
  isDeletable: false,
  isEditable: false,
  items: [
    {
      key: 'emission_designator',
      column: 'Emission Designator',
      isTag: false,
      isEditable: true,
    },
    { key: 'power', column: 'Power', isTag: false, isEditable: true },
    {
      key: 'tuning_step',
      column: 'Tuning Step',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'tuning_range_mhz_min',
      column: 'Tuning Range MHZ Min',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'tuning_range_mhz_max',
      column: 'Tuning Range MHZ Max',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'radar_tunability',
      column: 'Radar Tunability',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'sidelobe_suppression',
      column: 'Sidelob Suppression',
      isTag: false,
      isEditable: true,
    },
  ],
  subTitle: 'Pulses',
  subItems: [
    {
      key: 'pulse_duration_min',
      column: 'Pulse Duration Min',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'pulse_duration_max',
      column: 'Pulse Duration Max',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'pulse_repetition_rate_min',
      column: 'Pulse Repetition Rate Min',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'pulse_repetition_rate_max',
      column: 'Pulse Repetition Rate Max',
      isTag: false,
      isEditable: true,
    },
  ],
}

export const DeviceAntennaConfigs = {
  title: 'Device Antennas',
  isViewable: false,
  isDeletable: false,
  isEditable: false,
  items: [
    {
      key: 'nomaclature',
      column: 'Nomenclature',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'antenna_type',
      column: 'Antenna Type',
      isTag: true,
    },
    { key: 'serial', column: 'Serial', isTag: false },
  ],
  subTitle: 'TX Modes',
  subItems: [
    { key: 'mode', column: 'Mode', isTag: false, isEditable: true },
    {
      key: 'horizontal_scan_type',
      column: 'Horizontal Scan Type',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'vertical_scan_type',
      column: 'Vertical Scan Type',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'horizontal_beamwidth',
      column: 'Horizontal beamwith',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'vertical_beamwidth',
      column: 'Vertical beamwith',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'freq_min',
      column: 'Freq Min',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'freq_max',
      column: 'Freq Max',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'polarization_type',
      column: 'Polarization Type',
      isTag: false,
      isEditable: true,
    },
  ],
}

export const OpenFrequnecyRequestsConfigs = {
  title: 'Device Antennas',
  isViewable: false,
  isDeletable: false,
  isEditable: false,
  items: [
    {
      key: 'nomaclature',
      column: 'Nomenclature',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'antenna_type',
      column: 'Antenna Type',
      isTag: true,
    },
    { key: 'serial', column: 'Serial', isTag: false },
  ],
  subTitle: 'TX Modes',
  subItems: [
    { key: 'mode', column: 'Mode', isTag: false, isEditable: true },
    {
      key: 'horizontal_scan_type',
      column: 'Horizontal Scan Type',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'vertical_scan_type',
      column: 'Vertical Scan Type',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'horizontal_beamwidth',
      column: 'Horizontal beamwith',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'vertical_beamwidth',
      column: 'Vertical beamwith',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'freq_min',
      column: 'Freq Min',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'freq_max',
      column: 'Freq Max',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'polarization_type',
      column: 'Polarization Type',
      isTag: false,
      isEditable: true,
    },
  ],
}

export const FrequencyPoolAssignmentsTableConfig = {
  title: 'Frequency Pool Assignments',
  isViewable: true,
  isDeletable: false,
  isEditable: false,
  isSearchable: true,
  isNominating: true,
  items: [
    {
      key: 'id',
      column: 'ID',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_102',
      column: 'Field 102',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_107',
      column: 'Field 107',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_110_from',
      column: 'Frequency 110',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_110_to',
      column: 'Field 110 To',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_110_reference',
      column: 'Reference',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_130',
      column: 'Field 130',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_142',
      column: 'Field 142',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_143',
      column: 'Field 143',
      isTag: false,
      isEditable: true,
    },
    {
      key: 'field_144',
      column: 'Field 144',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'field_151',
      column: 'Field 151',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_200',
      column: 'Field 200',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'field_204',
      column: 'Field 204',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'field_205',
      column: 'Field 205',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'field_206',
      column: 'Field 206',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_207',
      column: 'Field 207',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'field_209',
      column: 'Field 209',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_300',
      column: 'Field 300',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'field_301',
      column: 'Field 301',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'field_341',
      column: 'Field 341',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'field_373',
      column: 'Field 373',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_400',
      column: 'Field 400',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'field_401',
      column: 'Field 401',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'field_402',
      column: 'Field 402',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'field_511',
      column: 'Field 511',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'field_512',
      column: 'Field 512',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'field_520',
      column: 'Field 520',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_530',
      column: 'Field 530',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_701',
      column: 'Field 701',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'field_702',
      column: 'Field 702',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_803',
      column: 'Field 803',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_911',
      column: 'Field 911',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_924',
      column: 'Field 924',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'field_927',
      column: 'Field 927',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_928',
      column: 'Field 928',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_956',
      column: 'Field 956',
      isTag: false,
      isEditable: false,
    },
    {
      key: 'field_958',
      column: 'Field 958',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'field_005',
      column: 'Field 005',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'field_010',
      column: 'Field 010',
      isTag: true,
      isEditable: false,
    },
    {
      key: 'raw_sfaf',
      column: 'Raw SFAF',
      isTag: false,
      isEditable: true,
    },
  ],
}

export const ManagerDashboardTabs = [
  {
    label: 'Requests',
    icon: SyncAltIcon,
    link: 'requests',
  },
  {
    label: 'Links Diagram',
    icon: KeyboardCommandKeyIcon,
    link: 'links-diagram',
  },
  {
    label: 'Field Controls',
    icon: ControlCameraIcon,
    isMenuCollapsed: false,
    subItems: [
      {
        label: 'Exercises',
        link: 'exercises',
        icon: FeaturedPlayListIcon,
      },
      {
        label: 'Locations',
        link: 'locations',
        icon: PlaceIcon,
      },
      {
        label: 'Units',
        link: 'units',
        icon: SchemaIcon,
      },
    ],
  },
  {
    label: 'Devices & Platforms',
    icon: AppSettingsAltOutlinedIcon,
    isMenuCollapsed: false,
    subItems: [
      {
        label: 'Devices',
        link: 'devices',
        icon: DevicesIcon,
      },
      {
        label: 'Platforms',
        link: 'platforms',
        icon: LayersIcon,
      },
    ],
  },
  {
    label: 'Licenses',
    link: 'licenses',
    icon: ReceiptLongIcon,
  },
]

export const ExerciseRequestsFormTabs = [
  {
    label: 'General',
  },
  {
    label: 'Location',
  },
  {
    label: 'Frequency',
  },
  {
    label: 'Equipment',
  },
]
