import { FC, useContext, useState } from 'react'
import { Node } from '@xyflow/react'
import {
  Box,
  Typography,
  IconButton,
  Button,
  Modal,
  TextField,
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  createPlaybook,
  updatePlaybook,
} from '../../../../services/playbook.service'
import { useToast } from '../../../../contexts/toast.context'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { createNode } from '../../../../utils/nodeCreator'

import styles from './styles.module.scss'

interface IProps {
  open: boolean
  onClose: () => void
}

const PlaybookCreateModal: FC<IProps> = ({ open, onClose }) => {
  const [name, setName] = useState('')

  const { showToast } = useToast()
  const { getPlaybooks } = useContext(PlaybookContext)

  const handleCreate = async () => {
    const data = await createPlaybook({
      name,
    })

    if (data.success && data.data) {
      getPlaybooks()
      showToast('success', data.message)

      let x = window.innerWidth / 2
      let y = window.innerHeight / 2

      let nodeId = data.data.nextNodeId

      const initialNodes: Node[] = [
        createNode(
          `${nodeId}`,
          x,
          y,
          { label: data.data.name, nodeId: nodeId },
          'text',
        ),
      ]

      await updatePlaybook({
        ...data.data,
        nodes: initialNodes,
        edges: [],
        autoLayoutOptions: {
          direction: 'TB',
          algorithm: 'd3-hierarchy',
        },
      })
      onClose()
    }
  }

  return (
    <Modal className={styles.modal} open={open} onClose={onClose}>
      <Box className={styles.modalContent}>
        <Box>
          <Box className={styles.header}>
            <Typography className={styles.title} id="title">
              Create a Playbook
            </Typography>
            <IconButton data-testid="closeButton" onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={styles.selects}>
            <TextField
              label="Name"
              id="playbookTitleInp"
              value={name}
              onChange={(evt) => setName(evt.target.value)}
            />
          </Box>
          <Box className={styles.actions}>
            <Button
              className={styles.removeBtn}
              color="success"
              id="createPlaybook"
              onClick={handleCreate}
            >
              Create
            </Button>
            <Button
              className={styles.cancelBtn}
              color="inherit"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default PlaybookCreateModal
