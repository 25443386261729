import { FC, useState } from 'react'
import {
  Box,
  Typography,
  IconButton,
  Button,
  Modal,
  TextField,
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import styles from './styles.module.scss'

interface IProps {
  open: boolean
  onClose: () => void
  handleConfirm: (subject: string) => void
}

const RFAExportModal: FC<IProps> = ({ open, onClose, handleConfirm }) => {
  const [subject, setSubject] = useState('')

  return (
    <Modal className={styles.modal} open={open} onClose={onClose}>
      <Box className={styles.modalContent}>
        <Box>
          <Box className={styles.header}>
            <Typography className={styles.title} id="title">
              Export RFA PDF
            </Typography>
            <IconButton data-testid="closeButton" onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={styles.selects}>
            <Box className={styles.fieldContainer}>
              <Typography>Subject:</Typography>
              <TextField
                className={styles.field}
                value={subject}
                onChange={(evt) => setSubject(evt.target.value)}
              />
            </Box>
          </Box>
          <Box className={styles.actions}>
            <Button variant="outlined" onClick={() => handleConfirm(subject)}>
              Download
            </Button>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default RFAExportModal
