import { memo, useMemo, useState, useRef, useEffect } from 'react'
import { toast } from 'react-toastify'
import FileSaver from 'file-saver'
import { useAuth0 } from '@auth0/auth0-react'
import { useEmitters } from '../../../contexts/emitters.context'
import { ISelectedPoint } from '../types'
import { Marker, Tooltip, Circle, ImageOverlay } from 'react-leaflet'
import { LatLngTuple, LatLngBounds, latLngBounds, LeafletEvent } from 'leaflet'
import { createHeatmapSimulation } from '../../../services/heatmap.service'
import prepareHeatmapParams from '../../../utils/prepareAreaRequestData'
import PointDetails from '../PointDetails'
import UpdateModal from './UpdateModal'
import HeatmapSettingsModal from './HeatmapSettingsModal'
import { IHeatmapData, IHeatmapParams } from '../../../types/cloudRF.type'
import { IFrequencyAssignmentWithCoordinates } from '../../../types/exercise.type'
import { SelectChangeEvent } from '@mui/material'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import styles from './styles.module.scss'

interface IProps {
  groupIndex: number
  isSelected: boolean
  onSelect: (data: ISelectedPoint) => void
}

interface IHeatmapLoadingStatus {
  [id: string]: {
    heatmap: number
    simulation: number
  }
}

const MapEntity: React.FC<IProps> = ({
  groupIndex,
  isSelected,
  onSelect,
}) => {
  const {
    data,
    selectEmitterData,
    fetchHeatmapForEmitter,
    handleChangeDataBy,
  } = useEmitters()

  const points = useMemo(() => data[groupIndex], [data])

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isHeatmapParamsModalOpen, setIsHeatmapParamsModalOpen] = useState(false)
  const [heatmapSimulation, setHeatmapSimulation] = useState<IHeatmapData | null>(null)
  const [simulationLoading, setSimulationLoading] = useState(false)
  const [showHeatmap, setShowHeatmap] = useState(false)
  const [showHeatmapSimulation, setShowHeatmapSimulation] = useState(false)
  const [showRadius, setShowRadius] = useState(false)
  // Don't build this data structure every time we render, only once. See heatmapLoadingStatus:
  const startingHeatmapLoadingStatus = useMemo<IHeatmapLoadingStatus>(
    () => points.reduce((result, elem) => (
      {...result, [elem.id_override]: {heatmap: 0, simulation: 0}}
    ), {}),
    [points]
  )
  /*
   * Contains an object with one entry for each frequency assignment,
   * then each of those has one entry for each heatmap type (i.e. the main heatmap and the simulation).
   * The number is the number of failures loading the image.
   * CloudRF often gives us a result from the heatmap request
   * before they are actually ready to serve the resulting png,
   * so then we get an error when we try to draw it on the map.
   * We want to retry a few times then give up.
   */
  const [heatmapLoadingStatus, setHeatmapLoadingStatus] = useState<IHeatmapLoadingStatus>(startingHeatmapLoadingStatus)
  // cancel timers when we unmount:
  const timeoutHandles = useRef<number[]>([])
  useEffect(() => {
    return () => {
      while (timeoutHandles.current.length) {
        const handle = timeoutHandles.current.pop()
        clearTimeout(handle)
      }
    }
  }, [])

  // The specific frequency assignment selected in the dropdown,
  // since a pin can have several:
  const selectedFrequencyAssignment = useMemo<IFrequencyAssignmentWithCoordinates>(
    () => points.find((el) => el.selected)!,
    [points]
  )

  const position = useMemo<LatLngTuple>(
    () => [selectedFrequencyAssignment.latitude, selectedFrequencyAssignment.longitude],
    [selectedFrequencyAssignment]
  )

  const selectedHeatmap = useMemo(
    () => {
      return points ? points.find((el) => el.selected)?.heatmap : null
    },
    [points, data]
  )

  const heatmapBounds = useMemo<LatLngBounds | null>(
    () => selectedFrequencyAssignment?.heatmap?.bounds ? latLngBounds(
      [selectedFrequencyAssignment.heatmap.bounds[0], selectedFrequencyAssignment.heatmap.bounds[1]],
      [selectedFrequencyAssignment.heatmap.bounds[2], selectedFrequencyAssignment.heatmap.bounds[3]],
    ) : null,
    [selectedHeatmap]
  )

  const heatmapSimulationBounds = useMemo<LatLngBounds | null>(
    () => heatmapSimulation?.bounds ? latLngBounds(
      [heatmapSimulation.bounds[0], heatmapSimulation.bounds[1]],
      [heatmapSimulation.bounds[2], heatmapSimulation.bounds[3]],
    ) : null,
    [heatmapSimulation]
  )

  const fetchHeatmap = () => {
    if (
      selectedFrequencyAssignment &&
      !selectedFrequencyAssignment.heatmap &&
      selectedFrequencyAssignment.exercise_request_location_radius &&
      !selectedFrequencyAssignment.loading
    ) {
      fetchHeatmapForEmitter({ id_override: selectedFrequencyAssignment.id_override })
    }
  }

  const handleMarkerClick = async () => {
    onSelect({
      index: groupIndex,
      points: points,
      focused: true
    })
  }

  const handleSelectChange = (
    event: SelectChangeEvent<string>,
  ) => {
    const selectedId = event.target.value
    selectEmitterData(selectedFrequencyAssignment!.id_override, selectedId)
  }

  const handleHeatmapClick = () => {
    fetchHeatmap();
    setShowHeatmap((prev) => !prev);
  }

  const handleSimulateHeatmap = async (params: IHeatmapParams) => {
    setSimulationLoading(true)
    const resp = await createHeatmapSimulation(params)

    if (resp.success && resp.data) {
      setHeatmapSimulation(resp.data)
      setSimulationLoading(false)
    } else {
      toast.error(resp.message)
    }
  }

  const handleExport = async (format: 'kml' | 'kmz') => {
    if (selectedFrequencyAssignment === null || !selectedFrequencyAssignment.heatmap?.kmz) return

    try {
      const blob = await (await fetch(selectedFrequencyAssignment.heatmap.kmz)).blob()
      FileSaver.saveAs(blob, `${selectedFrequencyAssignment.id_override}.${format}`)
    } catch (error) {
      console.error(`Error during ${format} export: `, error)
    }
  }

  const handleUpdate = async (
    updatedPoint: Partial<IFrequencyAssignmentWithCoordinates>
  ) => {
    if (selectedFrequencyAssignment === null) return;

    if (
      updatedPoint.exercise_request_location_radius ||
      updatedPoint.exercise_request_assigned_frequency_value
    ) {
      handleChangeDataBy(
        { id_override: selectedFrequencyAssignment.id_override },
        { ...updatedPoint, loading: true, heatmap: null }
      )

      // Now we *also* generate a new heatmap simulation,
      // so that it has the actual heatmap as a baseline.
      // TODO: Really? I'm just guessing at the original intent here.
      // Also do we really want to use the simulation result as the actual heatmap??
      // That seems to be what we're doing.
      const resp = await createHeatmapSimulation(
        prepareHeatmapParams({
          lat: selectedFrequencyAssignment.latitude,
          lon: selectedFrequencyAssignment.longitude,
          frq:
            updatedPoint.exercise_request_assigned_frequency_value ??
            selectedFrequencyAssignment.exercise_request_assigned_frequency_value,
          rad:
            updatedPoint.exercise_request_location_radius ??
            selectedFrequencyAssignment.exercise_request_location_radius,
        })
      )

      if (resp.success && resp.data) {
        handleChangeDataBy(
          { id_override: selectedFrequencyAssignment.id_override },
          { loading: false, heatmap: resp.data }
        )
      } else {
        toast.error(resp.message)
      }
    } else {
      handleChangeDataBy(
        { id_override: selectedFrequencyAssignment.id_override },
        { ...updatedPoint }
      )
    }
  }

  /*
   * generateHandleHeatmapImageError - creates an error handler for either the heatmap or the simulation,
   * so that we can track the number of failures for each separately.
   */
  const generateHandleHeatmapImageError = (topic: 'heatmap' | 'simulation') => {
    const heatmapRetryDelayMilliseconds = 1000
    const maxHeatmapErrors = 5
    return (event: LeafletEvent) => {
      const id = selectedFrequencyAssignment.id_override
      const failures = heatmapLoadingStatus[id][topic] + 1
      setHeatmapLoadingStatus((prev) => ({...prev, [id]: {...prev[id], [topic]: failures}}))
      // Unfortunately the onerror event doesn't include any information about why the image failed to load,
      // so just show something generic.
      console.warn(`Error loading ${id} ${topic}, attempt ${failures}`, event)
      if (failures > maxHeatmapErrors) {
        toast.error(`Error displaying ${id} ${topic}`)
        event.target._image.style.display = 'none'
        // Reset to zero failures, so that if the user Hides then Shows we will try again.
        setHeatmapLoadingStatus((prev) => ({...prev, [id]: {...prev[id], [topic]: 0}}))
      } else {
        const timeoutHandle = window.setTimeout(() => {
          // Remove the handle. O(n) is fine here:
          const pos = timeoutHandles.current.indexOf(timeoutHandle)
          if (pos >= 0) timeoutHandles.current.splice(pos, 1)

          // Reload the image:
          event.target._image.src = event.target._image.src
        }, heatmapRetryDelayMilliseconds * failures)
        timeoutHandles.current.push(timeoutHandle)
      }
    }
  }
  /* generateHandleHeatmapImageLoad - clears the error count for the given heatmap. */
  const generateHandleHeatmapImageLoad = (topic: 'heatmap' | 'simulation') => {
    return () => {
      const id = selectedFrequencyAssignment.id_override
      console.debug(`successfully loaded ${id} ${topic}`)
      setHeatmapLoadingStatus((prev) => ({...prev, [id]: {...prev[id], [topic]: 0}}))
    }
  }

  if (!selectedFrequencyAssignment) return null

  return (
    <>
      <Marker
        position={position}
        eventHandlers={{click: handleMarkerClick}}>
        <Tooltip>
          <PointDetails point={selectedFrequencyAssignment} />
        </Tooltip>
      </Marker>
      {isSelected &&
        <div className={styles.spexPinPopup}>
          <Stack style={{ padding: '20px 10px' }}>
            <Typography>{selectedFrequencyAssignment.exercise_name}</Typography>
            <Select
              MenuProps={{style: {zIndex: 6010}}}
              value={selectedFrequencyAssignment.id_override}
              onChange={handleSelectChange}
              disabled={selectedFrequencyAssignment.loading || simulationLoading}
            >
              {points.map((point) => (
                <MenuItem key={point.id_override} value={point.id_override}>{point.id_override}</MenuItem>
              ))}
            </Select>

            {(selectedFrequencyAssignment.loading || simulationLoading) && (
              <span style={{ marginLeft: '5px' }}>Loading...</span>
            )}
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: 10 }}>
              <div style={{ display: 'flex', gap: 12 }}>
                <Button variant="contained" onClick={() => setShowRadius((prev) => !prev)}>
                  {showRadius ? 'Hide' : 'Show'} Radius
                </Button>
                <Button variant="contained" onClick={handleHeatmapClick}>
                  {showHeatmap ? 'Hide' : 'Show'} Heatmap
                </Button>
                <Button variant="contained" onClick={() => setShowHeatmapSimulation((prev) => !prev)}>
                  {showHeatmapSimulation ? 'Hide' : 'Show'} Heatmap Simulation
                </Button>
              </div>
            </div>
            <div>
              <p>
                <strong>ID Override:</strong> {selectedFrequencyAssignment.id_override}
              </p>
              <p>
                <strong>Exercise Name:</strong> {selectedFrequencyAssignment.exercise_name}
              </p>
              <div>
                <div style={{ display: 'flex', gap: 12 }}>
                  <Button variant="contained" onClick={() => setIsEditModalOpen(true)}>
                    Edit record
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setIsHeatmapParamsModalOpen(true)}
                  >
                    Change heatmap params
                  </Button>
                </div>
                <p>Export as:</p>
                <div style={{ display: 'flex', gap: 12 }}>
                  <Button
                    variant="contained"
                    disabled={!selectedFrequencyAssignment.heatmap?.kmz}
                    onClick={() => handleExport('kml')}
                  >
                    KML
                  </Button>

                  <Button
                    variant="contained"
                    disabled={!selectedFrequencyAssignment.heatmap?.kmz}
                    onClick={() => handleExport('kmz')}
                  >
                    KMZ
                  </Button>
                </div>
              </div>
            </div>
          </Stack>
        </div>
      }
      {showRadius && selectedFrequencyAssignment && position &&
        (<Circle center={position} radius={selectedFrequencyAssignment.exercise_request_location_radius * 1000} />)
      }
      {showHeatmap && selectedFrequencyAssignment && selectedHeatmap?.PNG_Mercator && heatmapBounds &&
        <ImageOverlay
          url={selectedHeatmap.PNG_Mercator}
          bounds={heatmapBounds}
          eventHandlers={{error: generateHandleHeatmapImageError('heatmap'), load: generateHandleHeatmapImageLoad('heatmap')}}
          />
      }
      {showHeatmapSimulation && heatmapSimulation?.PNG_Mercator && heatmapSimulationBounds &&
        <ImageOverlay
          url={heatmapSimulation.PNG_Mercator}
          bounds={heatmapSimulationBounds}
          eventHandlers={{error: generateHandleHeatmapImageError('simulation'), load: generateHandleHeatmapImageLoad('simulation')}}
          />
      }

      {selectedFrequencyAssignment &&
      <UpdateModal
        point={selectedFrequencyAssignment}
        onUpdate={handleUpdate}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
      />
      }

      {selectedFrequencyAssignment &&
      <HeatmapSettingsModal
        initialValues={prepareHeatmapParams({
          lat: selectedFrequencyAssignment.latitude,
          lon: selectedFrequencyAssignment.longitude,
          frq: selectedFrequencyAssignment.exercise_request_assigned_frequency_value,
          rad: selectedFrequencyAssignment.exercise_request_location_radius,
        })}
        onUpdate={handleSimulateHeatmap}
        isOpen={isHeatmapParamsModalOpen}
        onClose={() => setIsHeatmapParamsModalOpen(false)}
      />
      }
    </>
  )
}

export default memo(MapEntity)
