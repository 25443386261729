import { Grid, TextField } from '@mui/material'
import { FC } from 'react'

interface IProps {
  values: { min: number; max: number }
  onChange: (value: { min: number; max: number }) => void
}

const FrequencyRangeInput: FC<IProps> = ({ values, onChange }) => {
  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const min = parseFloat(event.target.value)
    onChange({ min, max: values.max })
  }

  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const max = parseFloat(event.target.value)
    onChange({ min: values.min, max })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          label="Min Frequency (MHz)"
          type="number"
          value={values.min}
          onChange={handleMinChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Max Frequency (MHz)"
          type="number"
          value={values.max}
          onChange={handleMaxChange}
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

export default FrequencyRangeInput
