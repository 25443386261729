import React, { forwardRef } from 'react'
import cn from 'classnames'
import scss from './styles.module.scss'

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: string
}

const Input = forwardRef<HTMLInputElement, IProps>(
  ({ label, error, className, ...props }, ref) => {
    const inputClass = cn(scss.input, {
      [scss.error]: !!error,
    })

    return (
      <div className={cn(scss.container, className)}>
        {label && <label className={scss.label}>{label}</label>}
        <input ref={ref} className={inputClass} {...props} />
        {error && <span className={scss.error}>{error}</span>}
      </div>
    )
  }
)

Input.displayName = 'Input'

export default Input
