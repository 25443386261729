import { FC, useContext } from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Box, Typography, IconButton, Button, Modal } from '@mui/material'
import { useToast } from '../../../../contexts/toast.context'
import { EventsContext } from '../../../../contexts/events.context'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import {
  runPlaybook,
  stopPlaybook,
  updatePlaybook,
} from '../../../../services/playbook.service'
import { IPlaybook } from '../../../../types/playbook.type'

import styles from './styles.module.scss'

interface IProps {
  playbook: IPlaybook | undefined
  isOpen: boolean
  onClose: () => void
}

const PlaybookEnableModal: FC<IProps> = ({ playbook, isOpen, onClose }) => {
  const { setPlaybook, setInitialPlaybook, user } = useContext(PlaybookContext)
  const { eventsRoom } = useContext(EventsContext)
  const { showToast } = useToast()

  const handleStart = async () => {
    if (playbook) {
      playbook.enabled = !playbook.enabled

      setPlaybook({ ...playbook })
      onClose()

      const data = await updatePlaybook(playbook)

      setInitialPlaybook(playbook)

      if (data.success) {
        if (playbook.enabled) {
          const result = await runPlaybook(playbook.id, eventsRoom, user)

          if (result.success) {
            showToast('success', result.message)

            playbook.enabled = !playbook.enabled

            setPlaybook({ ...playbook })
            await updatePlaybook(playbook)
          }
        } else {
          await stopPlaybook(playbook.id, eventsRoom)
        }
      } else {
        showToast('error', data.message)
      }
    }
  }

  return (
    <Modal className={styles.modal} open={isOpen} onClose={onClose}>
      <Box className={styles.modalContent}>
        <Box>
          <Box className={styles.header}>
            <Typography
              data-testid="title"
              id="enableTitle"
              className={styles.title}
            >
              Are you sure you want to {playbook?.enabled ? 'stop' : 'start'}{' '}
              {`"${playbook?.name}"`} playbook?
            </Typography>
            <IconButton data-testid="closeButton" onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={styles.actions}>
            <Button
              id="runPlaybook"
              className={styles.addBtn}
              style={{ color: playbook?.enabled ? '#F8CB45' : 'green' }}
              onClick={handleStart}
            >
              {playbook?.enabled ? 'Stop' : 'Start'}
            </Button>
            <Button
              className={styles.cancelBtn}
              color="inherit"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default PlaybookEnableModal
