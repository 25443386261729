import { axiosInstance } from './axios.service'
import { IResponse } from '../types/response.type'
import { HeatmapEndpoints } from '../types/endpoint.type'
import { IHeatmapData, IHeatmapParams } from '../types/cloudRF.type'
import { ICreateHeatmap } from '../types/heatmap.type'

export const createHeatmap = async (requestData: ICreateHeatmap): Promise<IResponse<IHeatmapData>> => {
  try {
    const data = await axiosInstance.post(
      `${HeatmapEndpoints.CREATE_HEATMAP}`,
                       requestData
    )

    return {
      success: true,
      data: data.data,
      message: 'OK',
    }
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createHeatmapSimulation = async (requestData: IHeatmapParams): Promise<IResponse<IHeatmapData>> => {
  try {
    const data = await axiosInstance.post(
      `${HeatmapEndpoints.CREATE_SIMULATION}`,
                       requestData
               )

    return {
      success: true,
      data: data.data,
      message: 'OK',
    }
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}
