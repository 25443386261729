import {
  FC,
  useState,
  Dispatch,
  MouseEvent,
  useContext,
  SetStateAction,
  SyntheticEvent,
} from 'react'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import {
  Tab,
  Box,
  Menu,
  Tabs,
  Button,
  AppBar,
  MenuItem,
  Typography,
  SvgIconTypeMap,
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { useToast } from '../../../../contexts/toast.context'
import AllSFAFsModal from '../../Modals/AllSFAFsModal'
import { SelectWithSearch } from '../../../shared/SearchSelect'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'
import { ExerciseRequestContext } from '../../../../contexts/exercise-request.context'
import { IExerciseRequestDetails } from '../../../../types/spectrum-manager.type'
import { FrequencyPlansTableConfigs } from '../../../../utils/constants'

import styles from './styles.module.scss'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface IProps {
  value?: number
  selectedRequest?: IExerciseRequestDetails | null
  isDashboard?: boolean
  tabs?: Array<{
    label: string
    icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
      muiName: string
    }
  }>
  setValue?: Dispatch<SetStateAction<number>>
}

const Toolbar: FC<IProps> = ({
  tabs,
  value,
  setValue,
  isDashboard,
  selectedRequest,
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [isAllSFAFOpen, setIsAllSFAFOpen] = useState(false)
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)

  const { showToast } = useToast()
  const { exercises, locationTemplates } = useContext(ExerciseRequestContext)
  const { isLightMode } = useContext(PlaybookContext)
  const { filterOptions, frequencyPlans, setFilterOptions } = useContext(
    SpectrumManagerContext,
  )

  const handleChange = (_: SyntheticEvent, value: number) => {
    if (setValue) setValue(value)
  }

  const handleFrequencyPlanExport = () => {
    const data = frequencyPlans

    if (!data || !data.length) {
      showToast('warning', 'No data to export')
      return
    }

    const headers = FrequencyPlansTableConfigs.items.map((item) => item.column)

    const csvContent = [
      headers.join(','),
      ...data.map((row) =>
        FrequencyPlansTableConfigs.items
          .map((item) => JSON.stringify((row as any)[item.key as string] || ''))
          .join(','),
      ),
    ].join('\n')

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', `frequency_plan.csv`)
    link.style.visibility = 'hidden'

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setIsDropDownOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setIsDropDownOpen(false)
  }

  return (
    <Box
      className={styles.toolbar}
      style={{
        backgroundColor: isLightMode ? 'white' : '#13222e',
      }}
    >
      <Box className={styles.leftContainer}>
        {!isDashboard && (
          <AppBar position="static">
            <Tabs
              value={value}
              TabIndicatorProps={{ sx: { backgroundColor: 'transparent' } }}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              {tabs?.map((tab, index) => (
                <Tab
                  key={tab.label}
                  sx={{
                    backgroundColor:
                      index === value
                        ? isLightMode
                          ? 'rgba(204, 205, 207, 0.5)'
                          : 'rgba(255, 255, 255, 0.2)'
                        : '',
                    borderRadius: 10,
                  }}
                  {...a11yProps(index)}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {tab.icon && <tab.icon sx={{ width: 18, height: 18 }} />}
                      <Typography sx={{ fontSize: 15 }}>{tab.label}</Typography>
                    </Box>
                  }
                />
              ))}
            </Tabs>
          </AppBar>
        )}
      </Box>
      {isDashboard && (
        <Box className={styles.rightContainer}>
          <Box className={styles.exportContainer}>
            {value === 0 && (
              <Button
                onClick={handleClick}
                className={styles.exportBtn}
                variant="outlined"
                id="basic-button"
                aria-controls={isDropDownOpen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isDropDownOpen ? 'true' : undefined}
              >
                Export
              </Button>
            )}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={isDropDownOpen}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => setIsAllSFAFOpen(true)}>SFAFs</MenuItem>
              <MenuItem onClick={() => handleFrequencyPlanExport()}>
                Frequency Plan
              </MenuItem>
            </Menu>
          </Box>
          <SelectWithSearch
            label="Exercise"
            height={40}
            width={200}
            value={exercises
              .map((exercise) => ({
                value: exercise.id,
                label: exercise.name,
              }))
              .find((elem) => elem.value === filterOptions.exerciseId)}
            options={exercises.map((exercise) => ({
              value: exercise.id,
              label: exercise.name,
            }))}
            handleChange={(value) =>
              setFilterOptions((prev) => ({
                ...prev,
                exerciseId: value ? Number(value) : undefined,
              }))
            }
          />
          <SelectWithSearch
            width={200}
            height={40}
            label="Status"
            value={[
              'NOT_STARTED',
              'IN_PROGRESS',
              'COMPLETED',
              'TEST',
              'INCOMPLETE',
            ]
              .map((value) => ({ value, label: value }))
              .find((elem) => elem.value === filterOptions.status)}
            options={[
              'NOT_STARTED',
              'IN_PROGRESS',
              'COMPLETED',
              'TEST',
              'INCOMPLETE',
            ].map((value) => ({ value, label: value }))}
            handleChange={(value) =>
              setFilterOptions((prev) => ({
                ...prev,
                status: value ? String(value) : undefined,
              }))
            }
          />
          <SelectWithSearch
            width={200}
            height={40}
            options={locationTemplates.map((location) => ({
              value: location.id,
              label: location.transmitter_location,
            }))}
            value={locationTemplates
              .map((location) => ({
                value: location.id,
                label: location.transmitter_location,
              }))
              .find((elem) => elem.value === filterOptions.locationId)}
            handleChange={(value) =>
              setFilterOptions((prev) => ({
                ...prev,
                locationId: value ? Number(value) : undefined,
              }))
            }
            label="Location"
          />
        </Box>
      )}
      <AllSFAFsModal
        isLightMode={isLightMode}
        isOpen={isAllSFAFOpen}
        onClose={() => setIsAllSFAFOpen(false)}
        frequency_id={selectedRequest?.frequency_id || 0}
      />
    </Box>
  )
}

export default Toolbar
