import axios from 'axios'
import { axiosInstance } from './axios.service'
import { IResponse } from '../types/response.type'
import { ExerciseRequestFormEndpoints } from '../types/endpoint.type'
import {
  IExercise,
  IUsageTime,
  IStateRegion,
  IMajorFunction,
  IExerciseRequest,
  ILocationTemplate,
  IDetailedFunction,
  IUsageTimeService,
  IFrequencyTemplate,
  IIntermediateFunction,
  IExerciseRequestLocation,
  IExerciseRequestFrequency,
  IExerciseRequestFrequencySingle,
} from '../types/exercise.type'
import { IClassificationType } from '../types/classifications.type'
import {
  IOperatingSubUnit,
  IOperatingUnit,
} from '../types/operating-unit.types'

export const getAllExercises = async (): Promise<IResponse<IExercise[]>> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_EXERCISES,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getSingleExerciseRequest = async (
  id: number,
): Promise<IResponse<IExerciseRequest>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.GET_EXERCISE_REQUESTS}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllExerciseRequests = async (
  user_id: number | null,
  exercise_id: number | null,
  operating_unit_id: number | null,
): Promise<IResponse<IExerciseRequest[]>> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_EXERCISE_REQUESTS,
      {
        params: { exercise_id, user_id, operating_unit_id },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllOperatingUnits = async (): Promise<
  IResponse<IOperatingUnit[]>
> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_OPERATING_UNITS,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllOperatingSubUnits = async (
  operating_unit: number,
): Promise<IResponse<IOperatingSubUnit[]>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.GET_OPERATING_SUB_UNITS}/${operating_unit}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllClassificationTypes = async (): Promise<
  IResponse<IClassificationType[]>
> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_CLASSIFICATION_TYPES,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createExerciseRequest = async (
  exerciseId?: number,
): Promise<IResponse<IExerciseRequest>> => {
  try {
    const data = await axiosInstance.post(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST}`,
      {
        exerciseId: exerciseId || null,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateExerciseRequest = async (
  id: number,
  request: IExerciseRequest,
): Promise<IResponse<IExerciseRequest>> => {
  try {
    const data = await axiosInstance.put(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST}/${id}`,
      request,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteExerciseRequestById = async (
  id: number,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllLocations = async (
  id: number,
): Promise<IResponse<IExerciseRequestLocation[]>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_LOCATIONS}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createExerciseLocation = async (
  id: number,
): Promise<IResponse<IExerciseRequestLocation>> => {
  try {
    const data = await axiosInstance.post(
      ExerciseRequestFormEndpoints.EXERCISE_REQUEST_LOCATIONS,
      {
        exerciseRequestId: id,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateLocationById = async (
  id: number,
  params: {
    rad?: number
    lon_EW?: string
    lat_NS?: string
    lon_deg?: number
    lon_min?: number
    lon_sec?: number
    lat_deg?: number
    lat_min?: number
    lat_sec?: number
    elevation?: number
    loc_template?: number
    transmitter_loc?: string
    state_region_id?: number
  },
): Promise<IResponse<IExerciseRequestLocation>> => {
  try {
    const data = await axiosInstance.put(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_LOCATIONS}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteLocationById = async (
  id: number,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_LOCATIONS}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getLocationElevation = async (
  lat: number,
  long: number,
): Promise<any> => {
  try {
    const data = await axios.get(`
      https://api.open-elevation.com/api/v1/lookup?locations=${lat},${long}
    `)

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllLocationTemplates = async (): Promise<
  IResponse<ILocationTemplate[]>
> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_LOCATION_TEMPLATES,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllStateRegions = async (): Promise<
  IResponse<IStateRegion[]>
> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_STATE_REGIONS,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllFrequencies = async (
  id: number,
): Promise<IResponse<IExerciseRequestFrequency[]>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_FREQUENCIES}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createExerciseFrequency = async (
  id: number,
): Promise<IResponse<IExerciseRequestFrequency>> => {
  try {
    const data = await axiosInstance.post(
      ExerciseRequestFormEndpoints.EXERCISE_REQUEST_FREQUENCIES,
      {
        exerciseRequestId: id,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateFrequencyById = async (
  id: number,
  params: {
    hopset: string
    saturn: boolean
    platform: string
    locationId: number | null
    usageTimeId: number | null
    percentTime: number
    majorFunctionId: number | null
    receiveLocationId: number | null
    usageTimeServiceId: number | null
    detailedFunctionId: number | null
    numberOfFrequencies: number
    supplementaryDetails: string
    intermediateFunctionId: number | null
    isDifferentReceiveLocation: boolean
  },
): Promise<IResponse<IExerciseRequestFrequency>> => {
  try {
    const data = await axiosInstance.put(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_FREQUENCIES}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteFrequencyById = async (
  id: number,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_FREQUENCIES}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllMajorFunctions = async (): Promise<
  IResponse<IMajorFunction[]>
> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_MAJOR_FUNCTIONS,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getIntermediateFunctionsByMajorFunctionId = async (
  id: number,
): Promise<IResponse<IIntermediateFunction[]>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.GET_INTERMEDIATE_FUNCTIONS}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getDetailedFunctionsByIntermediateFunctionId = async (
  id: number,
): Promise<IResponse<IDetailedFunction[]>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.GET_DETAILED_FUNCTIONS}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllSingleFrequencies = async (
  id: number,
): Promise<IResponse<IExerciseRequestFrequencySingle[]>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_FREQUENCIES_SINGLE}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createFrequencySingle = async (
  id: number,
  isExcluded?: boolean,
): Promise<IResponse<IExerciseRequestFrequencySingle>> => {
  try {
    const data = await axiosInstance.post(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_FREQUENCIES_SINGLE}/${id}`,
      {
        isExcluded,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateSingleFrequency = async (
  id: number,
  params: {
    start_freq: number
    start_indicator: string
    frequency_template_id: number
    end_freq: number
    end_indicator: string
    is_band: boolean
  },
): Promise<IResponse<IExerciseRequestFrequencySingle>> => {
  try {
    const data = await axiosInstance.put(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_FREQUENCIES_SINGLE}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteSingleFrequency = async (
  id: number,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_FREQUENCIES_SINGLE}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllFrequencyTemplates = async (): Promise<
  IResponse<IFrequencyTemplate[]>
> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_FREQUENCY_TEMPLATES,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllUsageTimes = async (): Promise<IResponse<IUsageTime[]>> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_USAGE_TIMES,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}
export const getAllUsageTimeServices = async (): Promise<
  IResponse<IUsageTimeService[]>
> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_USAGE_TIME_SERVICES,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}
