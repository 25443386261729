import { useContext, useEffect } from 'react'
import { Box } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import Loader from './components/shared/Loader'
import ErrorPage from './components/shared/ErrorPage'
import RoutesComponent from './routers'
import { AuthContext } from './contexts/auth.context'
import { configureAxiosAuth } from './services/axios.service'
import { HistoryRouter } from './utils/history'
import { PlaybookContext } from './contexts/playbooks.context'
import { getUserByEmail } from './services/auth.service'

import './styles/global.scss'

const App = () => {
  const { isLoading, isAuthenticated, error, user, getAccessTokenSilently } =
    useAuth0()

  const { isLightMode } = useContext(PlaybookContext)
  const { user: AuthUser, setUser } = useContext(AuthContext)

  const getUser = async () => {
    configureAxiosAuth(getAccessTokenSilently)

    if (user?.email) {
      const data = await getUserByEmail(user.email, user.nickname || '')

      if (data.data) {
        setUser(data.data)
      }
    }
  }

  useEffect(() => {
    if (user) {
      getUser()
    }
  }, [user])

  useEffect(() => {
    configureAxiosAuth(getAccessTokenSilently)
  }, [getAccessTokenSilently])

  useEffect(() => {
    const rootElement = document.documentElement

    if (isLightMode) {
      rootElement.classList.add('light-mode')
      rootElement.classList.remove('dark-mode')
    } else {
      rootElement.classList.add('dark-mode')
      rootElement.classList.remove('light-mode')
    }
  }, [isLightMode])

  if (error) {
    return <ErrorPage code={400} message={error.message} />
  }

  if (isLoading || (isAuthenticated && !AuthUser)) {
    return <Loader />
  }

  return (
    <Box className="App">
      <HistoryRouter>
        <RoutesComponent />
      </HistoryRouter>
    </Box>
  )
}

export default App
