import { createContext, ReactNode, useEffect, useState, useContext } from 'react'
import { getAllAdditionalActions } from '../services/additional-actions.service'
import {
  IAdditionalAction,
  IAdditionalActionsContext,
} from '../types/additional-actions.type'
import { AuthContext } from './auth.context'

export const AdditionalActionsContext =
  createContext<IAdditionalActionsContext>({
    additionalActions: [],
    getAdditionalActions: () => {},
    setAdditionalActions: () => {},
  })

export const AdditionalActionsProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [additionalActions, setAdditionalActions] = useState<
    IAdditionalAction[]
  >([])

  const { user } = useContext(AuthContext)

  const getAdditionalActions = async () => {
    const data = await getAllAdditionalActions()

    if (data.success && data.data) {
      setAdditionalActions(data.data)
    }
  }

  useEffect(() => {
    if (!user) return

    getAdditionalActions()
  }, [user])

  return (
    <AdditionalActionsContext.Provider
      value={{ additionalActions, getAdditionalActions, setAdditionalActions }}
    >
      {children}
    </AdditionalActionsContext.Provider>
  )
}
