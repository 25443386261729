import { useContext } from 'react'
import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { PlaybookContext } from '../../contexts/playbooks.context'
import DashboardSidebar from '../../components/features/SpectrumManagerDashboard/Sidebar'

import styles from './styles.module.scss'

const SpectrumManagerDashboard = () => {
  const { isLightMode } = useContext(PlaybookContext)

  return (
    <Box className={`${styles.spectrumManager} main-container`}>
      <DashboardSidebar isLightMode={isLightMode} />
      <Box className={`${styles.content} sub-container`}>
        <Outlet />
      </Box>
    </Box>
  )
}

export default SpectrumManagerDashboard
