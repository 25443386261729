import React from 'react'
import FileUploader from '../../../../components/ui/FileUploader'
import * as zip from '@zip.js/zip.js'
import * as tj from '@tmcw/togeojson'
import { GeoJsonObject } from 'geojson'
import { toast } from 'react-toastify'

interface IProps {
  onKMLLoaded: (gj: GeoJsonObject) => void
}

const KMLFileReader: React.FC<IProps> = ({ onKMLLoaded }) => {
  const handleFileChange = async (files: FileList | null) => {
    if (files === null) return;

    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      const blob = new Blob([file], {
        type: 'application/vnd.google-earth.kml+xml',
      })

      // If the extension is .kmz, try to unzip it,
      // otherwise assume it's a kml.
      let kml = null;
      if (file.name.endsWith('.kmz')) {
        const blobReader = new zip.BlobReader(blob)
        let zipReader = null
        try {
          zipReader = new zip.ZipReader(blobReader)
          const writer = new zip.TextWriter()
          const entries = await zipReader.getEntries()
          const entry = entries[0]
          if (entry && entry.getData) {
            kml = await entry.getData(writer)
          }
        } catch (err: any) {
          if (err.toString)
            toast.error(err.toString())
          else
            toast.error("Failed to read zip file")
          continue
        } finally {
          if (zipReader)
            await zipReader.close()
        }
      } else {
        kml = await blob.text()
      }

      if (!kml) {
        toast.error("No KML found")
        continue
      }

      // Convert it to geojson so leaflet can understand:
      // Parse the kml because toGeoJSON needs a DOM input.
      const xml = new DOMParser().parseFromString(kml, 'text/xml')
      const err = xml.querySelector('parsererror')
      if (err) {
        toast.error(err.innerHTML)
        continue
      }
      const json = tj.kml(xml)
      if (!json.features.length) {
        toast.error("No features found in KML file")
        continue
      }

      onKMLLoaded(json)
    }
  }

  return (
    <div>
      <FileUploader
        label="Upload kml/kmz file"
        accept=".kml, .kmz"
        clearAfterUpload
        onFileSelect={handleFileChange}
      />
    </div>
  )
}

export default KMLFileReader
