import { useContext, useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { IArcDiagramNodes } from '../../../../types/spectrum-manager.type'
import { getAllArcDiagramNodes } from '../../../../services/spectrum-manager.service'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'

import styles from './styles.module.scss'

const ArcDiagramIframe = () => {
  const [sort, setSort] = useState('Group')
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [model, setModel] = useState<{ nodes: IArcDiagramNodes[] }>({
    nodes: [],
  })

  const { filterOptions } = useContext(SpectrumManagerContext)

  const getArcDiagramNodes = async () => {
    const data = await getAllArcDiagramNodes(filterOptions)

    if (data.data) {
      setModel({ nodes: data.data })
    }
  }

  useEffect(() => {
    getArcDiagramNodes()
  }, [filterOptions])

  useEffect(() => {
    const sendModelToIframe = () => {
      if (iframeRef.current && iframeRef.current.contentWindow) {
        iframeRef.current.contentWindow.postMessage({ model, sort }, '*')
      }
    }

    if (iframeRef.current) {
      if (iframeRef.current.contentWindow?.document.readyState === 'complete') {
        sendModelToIframe()
      } else {
        iframeRef.current.onload = sendModelToIframe
      }
    }
  }, [model, sort])

  return (
    <Box className={styles.linksDiagram}>
      <iframe
        ref={iframeRef}
        src="/arc-diagram.html"
        width="100%"
        height="800"
        style={{ border: 'none' }}
      />
    </Box>
  )
}

export default ArcDiagramIframe
