import { useContext, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useToast } from '../../../../contexts/toast.context'
import TableComponent from '../../../shared/Table'
import PlatformModal from '../../Modals/PlatformModal'
import { IPlatform } from '../../../../types/spectrum-manager.type'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { PlatformsConfigs } from '../../../../utils/constants'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'
import {
  createPlatform,
  deletePlatform,
  updatePlatform,
} from '../../../../services/spectrum-manager.service'

import styles from './styles.module.scss'

export const Platforms = () => {
  const [isCreating, setIsCreating] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [platform, setPlatform] = useState<IPlatform | null>(null)

  const { showToast } = useToast()
  const { isLightMode } = useContext(PlaybookContext)
  const { platforms, setPlatforms } = useContext(SpectrumManagerContext)

  const handleRemovePlatform = async (id: number) => {
    const data = await deletePlatform(id)

    if (data.success) {
      setPlatforms(platforms.filter((platform) => platform.id !== id))
      showToast('success', 'Platform is deleted successfully')
    }
  }

  const handleCreatePlatform = async () => {
    if (platform) {
      const data = await createPlatform(platform)

      if (data.data) {
        setPlatforms([data.data, ...platforms])
        showToast('success', 'Platform is created successfully')
        setIsCreating(false)
        setPlatform(null)
      }
    }
  }

  const handleSave = async () => {
    if (isCreating) {
      handleCreatePlatform()
      return
    }

    if (platform) {
      const data = await updatePlatform(platform.id, platform)

      if (data.data) {
        setPlatforms((prev) =>
          prev.map((platform) =>
            data.data && platform.id === data.data.id ? data.data : platform,
          ),
        )
        showToast('success', 'Platform is updated successfully')
        setPlatform(null)
        setIsEditing(false)
      }
    }
  }

  const onPlatformCreate = () => {
    setIsCreating(true)
    setPlatform({} as IPlatform)
  }

  const handleChange = async (key: string, value: string) => {
    const changedPlatform = platform ? platform : ({} as IPlatform)

    setPlatform({ ...changedPlatform, [key]: value })
  }

  return (
    <Box className={styles.platforms}>
      <TableComponent
        isLightMode={isLightMode}
        data={platforms}
        configs={PlatformsConfigs}
        handleRefetch={() => {}}
        handleCreate={onPlatformCreate}
        handleView={(item: IPlatform) => {
          setPlatform(item)
          setIsEditing(true)
        }}
        handleDelete={handleRemovePlatform}
      />
      {platform && (
        <PlatformModal
          platform={platform}
          isLightMode={isLightMode}
          open={isCreating || isEditing}
          handleSave={handleSave}
          fields={PlatformsConfigs.items}
          onClose={() => {
            setIsCreating(false)
            setIsEditing(false)
          }}
          handleChange={handleChange}
        />
      )}
    </Box>
  )
}
