import { useContext, useState } from 'react'
import {
  Box,
  Modal,
  Button,
  TextField,
  IconButton,
  Typography,
} from '@mui/material'
import TableComponent from '../../../shared/Table'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'
import {
  AddTemplateItems,
  LocationTemplateConfigs,
} from '../../../../utils/constants'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { SelectWithSearch } from '../../../shared/SearchSelect'
import { ILocationTemplateWithRegions } from '../../../../types/spectrum-manager.type'
import { ExerciseRequestContext } from '../../../../contexts/exercise-request.context'
import {
  createLocationTemplate,
  deleteLocationTemplate,
} from '../../../../services/spectrum-manager.service'
import { useToast } from '../../../../contexts/toast.context'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { SelectComponent } from '../../../shared/SelectComponent'

import styles from './styles.module.scss'

export const LocationTemplates = () => {
  const [isAdding, setIsAdding] = useState(false)
  const [locationTemplate, setLocationTemplate] =
    useState<ILocationTemplateWithRegions | null>(null)

  const { showToast } = useToast()
  const { locationTemplatesWithRegions, setLocationTemplatesWithRegions } =
    useContext(SpectrumManagerContext)

  const { isLightMode } = useContext(PlaybookContext)

  const { stateRegions } = useContext(ExerciseRequestContext)

  const handleTemplateChange = (key: string, value: string | number) => {
    const template = locationTemplate
      ? locationTemplate
      : ({} as ILocationTemplateWithRegions)

    setLocationTemplate({ ...template, [key]: value })
  }

  const handleRemoveLocation = async (id: number) => {
    const data = await deleteLocationTemplate(id)

    if (data.success) {
      setLocationTemplatesWithRegions(
        locationTemplatesWithRegions.filter((template) => template.id !== id),
      )

      showToast('success', 'Template removed successfully')
    }
  }

  const onClose = () => {
    setIsAdding(false)
  }

  const handleTemplateCreate = async () => {
    if (locationTemplate) {
      const data = await createLocationTemplate(locationTemplate)

      if (data.data) {
        setLocationTemplatesWithRegions([
          data.data,
          ...locationTemplatesWithRegions,
        ])
        showToast('success', 'Template created successfully')
        onClose()
      }
    }
  }

  return (
    <Box className={styles.locationTemplate}>
      <TableComponent
        isLightMode={isLightMode}
        handleCreate={() => setIsAdding(true)}
        data={locationTemplatesWithRegions}
        configs={LocationTemplateConfigs}
        handleRefetch={() => {}}
        handleDelete={handleRemoveLocation}
      />
      <Modal className={styles.modal} open={isAdding} onClose={onClose}>
        <Box className={styles.modalContent}>
          <Box className={styles.header}>
            <Typography data-testid="title" className={styles.title}>
              Add Location
            </Typography>
            <IconButton data-testid="closeButton" onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={styles.content}>
            <Box className={styles.addTemplate}>
              <Box className={styles.subContainer}>
                {AddTemplateItems.slice(0, AddTemplateItems.length / 2).map(
                  (item) => (
                    <Box key={item.column} className={styles.fieldContainer}>
                      <Typography className={styles.label}>
                        {item.column}{' '}
                        <Typography className={styles.asterisk}>*</Typography>:
                      </Typography>
                      {item.key !== 'latitude_ns' &&
                        item.key !== 'longitude_ew' &&
                        item.key !== 'state_region_type_id' && (
                          <TextField
                            label={item.column}
                            className={styles.field}
                            onChange={(evt) =>
                              handleTemplateChange(item.key, evt.target.value)
                            }
                          />
                        )}
                      {item.key === 'longitude_ew' && (
                        <SelectComponent
                          className={styles.field}
                          label="Longitude EW"
                          items={['E', 'W'].map((value) => ({
                            value,
                            displayValue: value,
                          }))}
                          handleChange={(value) =>
                            handleTemplateChange('longitude_ew', String(value))
                          }
                        />
                      )}
                      {item.key === 'state_region_type_id' && (
                        <SelectWithSearch
                          label="Regions"
                          width={250}
                          options={stateRegions.map((region) => ({
                            value: region.id,
                            label: region.name,
                          }))}
                          value={stateRegions
                            .map((region) => ({
                              value: region.id,
                              label: region.name,
                            }))
                            .find(
                              (item) =>
                                item.value ===
                                locationTemplate?.state_region_type_id,
                            )}
                          handleChange={(value) =>
                            handleTemplateChange(
                              'state_region_type_id',
                              Number(value),
                            )
                          }
                        />
                      )}
                    </Box>
                  ),
                )}
              </Box>
              <Box className={styles.subContainer}>
                {AddTemplateItems.slice(AddTemplateItems.length / 2).map(
                  (item) => (
                    <Box key={item.column} className={styles.fieldContainer}>
                      <Typography className={styles.label}>
                        {item.column}{' '}
                        <Typography className={styles.asterisk}>*</Typography>:
                      </Typography>
                      {item.key !== 'latitude_ns' &&
                        item.key !== 'longitude_ew' &&
                        item.key !== 'state_region_type_id' && (
                          <TextField
                            label={item.column}
                            className={styles.field}
                            onChange={(evt) =>
                              handleTemplateChange(item.key, evt.target.value)
                            }
                          />
                        )}
                      {item.key === 'latitude_ns' && (
                        <SelectComponent
                          className={styles.field}
                          label="Longitude EW"
                          items={['N', 'S'].map((value) => ({
                            value,
                            displayValue: value,
                          }))}
                          handleChange={(value) =>
                            handleTemplateChange('latitude_ns', String(value))
                          }
                        />
                      )}
                      {item.key === 'state_region_type_id' && (
                        <SelectWithSearch
                          label="Regions"
                          width={250}
                          options={stateRegions.map((region) => ({
                            value: region.id,
                            label: region.name,
                          }))}
                          value={stateRegions
                            .map((region) => ({
                              value: region.id,
                              label: region.name,
                            }))
                            .find(
                              (item) =>
                                item.value ===
                                locationTemplate?.state_region_type_id,
                            )}
                          handleChange={(value) =>
                            handleTemplateChange(
                              'state_region_type_id',
                              Number(value),
                            )
                          }
                        />
                      )}
                    </Box>
                  ),
                )}
              </Box>
            </Box>
          </Box>
          <Box className={styles.actions}>
            <Button
              id="confirmButton"
              data-testid="confirmButton"
              className={styles.removeBtn}
              onClick={handleTemplateCreate}
            >
              Create
            </Button>
            <Button
              className={styles.cancelBtn}
              color="inherit"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}
