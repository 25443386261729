import { FC, useContext, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import {
  createDeviceTransmitter,
  createDeviceTransmitterPulse,
  deleteDeviceTransmitter,
  deleteDeviceTransmitterPulse,
  getAllDeviceTransmitterPulse,
  updateDeviceTransmitter,
  updateDeviceTransmitterPulse,
} from '../../../../services/spectrum-manager.service'
import ExpandableTable from '../../../shared/ExpandableTable'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { IDeviceTransmitter } from '../../../../types/spectrum-manager.type'
import { DeviceTransmitterConfigs } from '../../../../utils/constants'

import styles from './styles.module.scss'

interface IProps {
  deviceId: number
  deviceTransmitters: IDeviceTransmitter[]
}

export const DevicesTransmitters: FC<IProps> = ({
  deviceTransmitters,
  deviceId,
}) => {
  const [deviceTransmitterWithPulses, setDeviceTransmitterWithPulses] =
    useState(deviceTransmitters)

  const { isLightMode } = useContext(PlaybookContext)

  const handleGetSubItems = async (id: number) => {
    const data = await getAllDeviceTransmitterPulse(id)

    if (data.data) {
      setDeviceTransmitterWithPulses(
        deviceTransmitterWithPulses.map((elem) =>
          elem.id === id ? { ...elem, items: data.data } : elem,
        ),
      )
    }
  }

  const handleCreate = async (transmitterId?: number) => {
    if (transmitterId) {
      const data = await createDeviceTransmitterPulse(transmitterId)

      if (data.data) {
        setDeviceTransmitterWithPulses(
          deviceTransmitterWithPulses.map((transmitter) => {
            if (transmitter.id === transmitterId && data.data) {
              const items = transmitter.items
                ? [...transmitter.items, data.data]
                : [data.data]

              return {
                ...transmitter,
                items,
              }
            }
            return transmitter
          }),
        )
      }
    } else {
      const data = await createDeviceTransmitter(deviceId)

      if (data.data) {
        setDeviceTransmitterWithPulses([
          data.data,
          ...deviceTransmitterWithPulses,
        ])
      }
    }
  }

  const handleChange = async (
    id: number,
    key: string,
    value: string,
    subItem?: number,
  ) => {
    if (subItem) {
      const deviceTransmitter = deviceTransmitterWithPulses.find(
        (elem) => elem.id === id,
      )

      if (deviceTransmitter) {
        const pulses = deviceTransmitter.items?.map((pulse) =>
          pulse.id === subItem ? { ...pulse, [key]: value } : pulse,
        )

        setDeviceTransmitterWithPulses(
          deviceTransmitterWithPulses.map((elem) =>
            elem.id === id ? { ...elem, items: pulses } : elem,
          ),
        )

        const pulse = pulses?.find((pulse) => pulse.id === subItem)

        if (pulse) {
          await updateDeviceTransmitterPulse(pulse.id, pulse)
        }
      }
    } else {
      const updatedTransmitters = deviceTransmitterWithPulses.map((elem) =>
        elem.id === id ? { ...elem, [key]: value } : elem,
      )
      setDeviceTransmitterWithPulses(updatedTransmitters)

      const updatedTransmitter = updatedTransmitters.find(
        (transmitter) => transmitter.id === id,
      )

      if (updatedTransmitter) {
        await updateDeviceTransmitter(updatedTransmitter.id, updatedTransmitter)
      }
    }
  }

  const handleDelete = async (id: number, subItemId?: number) => {
    if (subItemId) {
      const data = await deleteDeviceTransmitterPulse(subItemId)

      if (data.success) {
        setDeviceTransmitterWithPulses(
          deviceTransmitterWithPulses.map((transmitter) =>
            transmitter.id === id
              ? {
                  ...transmitter,
                  items: transmitter?.items?.filter(
                    (pulse) => pulse.id !== subItemId,
                  ),
                }
              : transmitter,
          ),
        )
      }
    } else {
      const data = await deleteDeviceTransmitter(id)

      if (data.success) {
        setDeviceTransmitterWithPulses(
          deviceTransmitterWithPulses.filter(
            (transmitter) => transmitter.id !== id,
          ),
        )
      }
    }
  }

  useEffect(() => {
    setDeviceTransmitterWithPulses(deviceTransmitters)
  }, [deviceTransmitters])

  return (
    <Box className={styles.deviceTransmitters}>
      <ExpandableTable
        configs={DeviceTransmitterConfigs}
        isLightMode={isLightMode}
        data={deviceTransmitterWithPulses}
        getSubItems={handleGetSubItems}
        handleChange={handleChange}
        handleCreate={handleCreate}
        handleDelete={handleDelete}
      />
    </Box>
  )
}
