import {
  IDevice,
  IDeviceAntenna,
  IExerciseRequestDeviceAntenna,
  IExerciseRequestEquipment,
  IExerciseRequestStationClass,
} from '../types/equipment.type'
import {
  IExercise,
  IExerciseRequestFrequency,
  IExerciseRequestFrequencySingle,
} from '../types/exercise.type'
import {
  IArcDiagramNodes,
  IAssignedFrequency,
  IDeviceAntennaMode,
  IDeviceTransmitter,
  IDeviceTransmitterPulse,
  IExerciseRequestDetails,
  IFrequencyPlan,
  IFrequencyPool,
  IFrequencyPool_113_114_115,
  IFrequencyPoolTableEquipment,
  IHopset,
  ILocationTemplateWithRegions,
  IOpenFrequencyRequest,
  IPlatform,
  ISFAFContents,
  ISFAFFrequency,
} from '../types/spectrum-manager.type'
import { IResponse } from '../types/response.type'
import { axiosInstance } from './axios.service'
import { IOperatingUnit } from '../types/operating-unit.types'
import { IAgencyOverride } from '../types/auth.type'
import { SpectrumManagerEndpoints } from '../types/endpoint.type'

export const getAllDetailedExerciseRequests = async (
  params: {
    status?: string
    exerciseId?: number
    locationId?: number
  },
  signal: AbortSignal,
): Promise<IResponse<IExerciseRequestDetails[]>> => {
  try {
    const data = await axiosInstance.get(
      SpectrumManagerEndpoints.EXERCISE_REQUESTS,
      {
        params,
        signal,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateFrequencyRequest = async (
  frequencyId: number,
  radius_306?: string | null,
  radius_406?: string | null,
  number_of_frequencies?: number | null,
): Promise<IResponse<IExerciseRequestFrequency>> => {
  try {
    const data = await axiosInstance.put(
      SpectrumManagerEndpoints.FREQUENCY_REQUEST,
      {
        frequencyId,
        radius_306,
        radius_406,
        number_of_frequencies,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllFrequencyPlans = async (params: {
  status?: string
  exerciseId?: number
  locationId?: number
}): Promise<IResponse<IFrequencyPlan[]>> => {
  try {
    const data = await axiosInstance.get(
      SpectrumManagerEndpoints.FREQUENCY_PLANS,
      {
        params,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllLocationTemplatesWithRegions = async (): Promise<
  IResponse<ILocationTemplateWithRegions[]>
> => {
  try {
    const data = await axiosInstance.get(
      SpectrumManagerEndpoints.LOCATION_TEMPLATES,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllPlatforms = async (): Promise<IResponse<IPlatform[]>> => {
  try {
    const data = await axiosInstance.get(SpectrumManagerEndpoints.PLATFORMS, {})

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getEquipmentsByFrequencyId = async (
  id: number,
): Promise<IResponse<IExerciseRequestEquipment[]>> => {
  try {
    const data = await axiosInstance.get(
      `${SpectrumManagerEndpoints.EQUIPMENT}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateEquipmentById = async (
  id: number,
  params: IExerciseRequestEquipment,
): Promise<IResponse<IExerciseRequestEquipment>> => {
  try {
    const data = await axiosInstance.put(
      `${SpectrumManagerEndpoints.EQUIPMENT}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAssignedFrequenciesByFrequencyId = async (
  id: number,
): Promise<IResponse<IAssignedFrequency[]>> => {
  try {
    const data = await axiosInstance.get(
      `${SpectrumManagerEndpoints.ASSIGNED_FREQUENCY}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateAssignedFrequencyById = async (
  id: number,
  params: IAssignedFrequency,
): Promise<IResponse<IAssignedFrequency>> => {
  try {
    const data = await axiosInstance.put(
      `${SpectrumManagerEndpoints.ASSIGNED_FREQUENCY}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createAssignedFrequency = async (
  exerciseRequestFrequencyId: number,
): Promise<IResponse<IAssignedFrequency>> => {
  try {
    const data = await axiosInstance.post(
      SpectrumManagerEndpoints.ASSIGNED_FREQUENCY,
      { exerciseRequestFrequencyId },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getFrequencyById = async (
  id: number,
): Promise<IResponse<IExerciseRequestFrequency>> => {
  try {
    const data = await axiosInstance.get(
      `${SpectrumManagerEndpoints.FREQUENCY}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateFrequencyById = async (
  id: number,
  params: IExerciseRequestFrequency,
): Promise<IResponse<IExerciseRequestFrequency>> => {
  try {
    const data = await axiosInstance.put(
      `${SpectrumManagerEndpoints.FREQUENCY}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateFrequencyRadiusCache = async (
  id: number,
  params: {
    radius_306: string
    radius_406: string
  },
): Promise<IResponse<IExerciseRequestFrequency>> => {
  try {
    const data = await axiosInstance.put(
      `${SpectrumManagerEndpoints.UPDATE_RADIUS_CACHE}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllSFAFFrequencies = async (params: {
  exerciseId?: number
  locationId?: number
}): Promise<IResponse<ISFAFFrequency[]>> => {
  try {
    const data = await axiosInstance.get(
      SpectrumManagerEndpoints.SFAF_FREQUENCIES,
      {
        params,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllSFAFEquipments = async (params: {
  exerciseId?: number
  locationId?: number
}): Promise<IResponse<IExerciseRequestEquipment[]>> => {
  try {
    const data = await axiosInstance.get(
      SpectrumManagerEndpoints.SFAF_EQUIPMENTS,
      {
        params,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllSFAFExcludedFrequencies = async (params: {
  exerciseId?: number
  locationId?: number
}): Promise<IResponse<IExerciseRequestFrequencySingle[]>> => {
  try {
    const data = await axiosInstance.get(
      SpectrumManagerEndpoints.SFAF_EXCLUDED_FREQUENCIES,
      {
        params,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllSFAFAntennas = async (params: {
  exerciseId?: number
  locationId?: number
}): Promise<
  IResponse<Array<IExerciseRequestDeviceAntenna & { code: string }>>
> => {
  try {
    const data = await axiosInstance.get(
      SpectrumManagerEndpoints.SFAF_ANTENNAS,
      {
        params,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllSFAFContents = async (params: {
  exerciseId?: number
  locationId?: number
}): Promise<IResponse<ISFAFContents[]>> => {
  try {
    const data = await axiosInstance.get(
      SpectrumManagerEndpoints.SFAF_CONTENTS,
      {
        params,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllSFAFStationClasses = async (params: {
  exerciseId?: number
  locationId?: number
}): Promise<
  IResponse<
    Array<
      IExerciseRequestStationClass & {
        station: string
        '113_output': string
        power_ranges_name: string
        emission_designator_simple_type_name: string
        emission_designator_name?: string
      }
    >
  >
> => {
  try {
    const data = await axiosInstance.get(
      SpectrumManagerEndpoints.SFAF_STATION_CLASSES,
      {
        params,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getSfafAgencyOverride = async (
  userId: number,
): Promise<IResponse<IAgencyOverride>> => {
  try {
    const data = await axiosInstance.get(
      `${SpectrumManagerEndpoints.AGENCY_OVERRIDE}/${userId}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllHopsets = async (): Promise<IResponse<IHopset[]>> => {
  try {
    const data = await axiosInstance.get(SpectrumManagerEndpoints.HOPSET, {})

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllArcDiagramNodes = async (params: {
  exerciseId?: number
  locationId?: number
  status?: string
}): Promise<IResponse<IArcDiagramNodes[]>> => {
  try {
    const data = await axiosInstance.get(
      SpectrumManagerEndpoints.ARC_DIAGRAM_NODES,
      {
        params,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createOperatingUnit = async (
  params: IOperatingUnit,
): Promise<IResponse<IOperatingUnit>> => {
  try {
    const data = await axiosInstance.post(
      SpectrumManagerEndpoints.OPERATING_UNIT,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createLocationTemplate = async (
  params: ILocationTemplateWithRegions,
): Promise<IResponse<ILocationTemplateWithRegions>> => {
  try {
    const data = await axiosInstance.post(
      SpectrumManagerEndpoints.LOCATION_TEMPLATES,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllLicenses = async (params: {
  exerciseId: number
  start_freq_long: number
  end_freq_long: number
}): Promise<IResponse<IFrequencyPool[]>> => {
  try {
    const data = await axiosInstance.get(SpectrumManagerEndpoints.LICENSE, {
      params,
    })

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllOpenFrequency = async (params: {
  locationId?: number
  status?: string
  freqStatus?: string
  exerciseId?: number
}): Promise<IResponse<IOpenFrequencyRequest[]>> => {
  try {
    const data = await axiosInstance.get(
      SpectrumManagerEndpoints.OPEN_FREQUENCY_REQUEST,
      {
        params,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteOperatingUnit = async (
  id: number,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${SpectrumManagerEndpoints.OPERATING_UNIT}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteLocationTemplate = async (
  id: number,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${SpectrumManagerEndpoints.LOCATION_TEMPLATES}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateExerciseRequestStatus = async (
  id: number,
  status: string,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.put(
      `${SpectrumManagerEndpoints.EXERCISE_REQUESTS}/${id}`,
      {
        status,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createExercise = async (
  params: IExercise,
): Promise<IResponse<IExercise>> => {
  try {
    const data = await axiosInstance.post(
      SpectrumManagerEndpoints.EXERCISE,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteExercise = async (id: number): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${SpectrumManagerEndpoints.EXERCISE}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllDeviceTransmitter = async (
  device_id: number,
): Promise<IResponse<IDeviceTransmitter[]>> => {
  try {
    const data = await axiosInstance.get(
      `${SpectrumManagerEndpoints.DEVICE_TRANSMITTER}/${device_id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createDeviceTransmitter = async (
  device_id: number,
): Promise<IResponse<IDeviceTransmitter>> => {
  try {
    const data = await axiosInstance.post(
      `${SpectrumManagerEndpoints.DEVICE_TRANSMITTER}/${device_id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateDeviceTransmitter = async (
  id: number,
  params: IDeviceTransmitter,
): Promise<IResponse<IDeviceTransmitter>> => {
  try {
    const data = await axiosInstance.put(
      `${SpectrumManagerEndpoints.DEVICE_TRANSMITTER}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteDeviceTransmitter = async (
  id: number,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${SpectrumManagerEndpoints.DEVICE_TRANSMITTER}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createDeviceAntenna = async (
  device_id: number,
): Promise<IResponse<IDeviceAntenna>> => {
  try {
    const data = await axiosInstance.post(
      `${SpectrumManagerEndpoints.DEVICE_ANTENNA}/${device_id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateDeviceAntenna = async (
  id: number,
  params: IDeviceAntenna,
): Promise<IResponse<IDeviceAntenna>> => {
  try {
    const data = await axiosInstance.put(
      `${SpectrumManagerEndpoints.DEVICE_ANTENNA}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteDeviceAntenna = async (
  id: number,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${SpectrumManagerEndpoints.DEVICE_ANTENNA}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllDeviceTransmitterPulse = async (
  device_transmitter_id: number,
): Promise<IResponse<IDeviceTransmitterPulse[]>> => {
  try {
    const data = await axiosInstance.get(
      `${SpectrumManagerEndpoints.DEVICE_TRANSMITTER_PULSE}/${device_transmitter_id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createDeviceTransmitterPulse = async (
  device_transmitter_id: number,
): Promise<IResponse<IDeviceTransmitterPulse>> => {
  try {
    const data = await axiosInstance.post(
      `${SpectrumManagerEndpoints.DEVICE_TRANSMITTER_PULSE}/${device_transmitter_id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateDeviceTransmitterPulse = async (
  id: number,
  params: IDeviceTransmitterPulse,
): Promise<IResponse<IDeviceTransmitterPulse>> => {
  try {
    const data = await axiosInstance.put(
      `${SpectrumManagerEndpoints.DEVICE_TRANSMITTER_PULSE}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteDeviceTransmitterPulse = async (
  id: number,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${SpectrumManagerEndpoints.DEVICE_TRANSMITTER_PULSE}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllDeviceAntennaModes = async (
  device_antenna_id: number,
): Promise<IResponse<IDeviceAntennaMode[]>> => {
  try {
    const data = await axiosInstance.get(
      `${SpectrumManagerEndpoints.DEVICE_ANTENNA_MODE}/${device_antenna_id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createDeviceAntennaMode = async (
  device_antenna_id: number,
): Promise<IResponse<IDeviceAntennaMode>> => {
  try {
    const data = await axiosInstance.post(
      `${SpectrumManagerEndpoints.DEVICE_ANTENNA_MODE}/${device_antenna_id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateDeviceAntennaMode = async (
  id: number,
  params: IDeviceAntennaMode,
): Promise<IResponse<IDeviceAntennaMode>> => {
  try {
    const data = await axiosInstance.put(
      `${SpectrumManagerEndpoints.DEVICE_ANTENNA_MODE}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteDeviceAntennaMode = async (
  id: number,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${SpectrumManagerEndpoints.DEVICE_ANTENNA_MODE}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createPlatform = async (
  params: IPlatform,
): Promise<IResponse<IPlatform>> => {
  try {
    const data = await axiosInstance.post(
      SpectrumManagerEndpoints.PLATFORMS,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updatePlatform = async (
  id: number,
  params: IPlatform,
): Promise<IResponse<IPlatform>> => {
  try {
    const data = await axiosInstance.put(
      `${SpectrumManagerEndpoints.PLATFORMS}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deletePlatform = async (id: number): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${SpectrumManagerEndpoints.PLATFORMS}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createDevice = async (
  params: IDevice,
): Promise<IResponse<IDevice>> => {
  try {
    const data = await axiosInstance.post(
      SpectrumManagerEndpoints.DEVICE,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateDevice = async (
  id: number,
  params: IDevice,
): Promise<IResponse<IDevice>> => {
  try {
    const data = await axiosInstance.put(
      `${SpectrumManagerEndpoints.DEVICE}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteDevice = async (id: number): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${SpectrumManagerEndpoints.DEVICE}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllFrequencyPoolTableEquipments = async (
  id: number,
): Promise<IResponse<IFrequencyPoolTableEquipment[]>> => {
  try {
    const data = await axiosInstance.get(
      `${SpectrumManagerEndpoints.FREQUENCY_POOL_EQUIPMENT}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const getAllFrequencyPool_113_114_115 = async (
  id: number,
): Promise<IResponse<IFrequencyPool_113_114_115[]>> => {
  try {
    const data = await axiosInstance.get(
      `${SpectrumManagerEndpoints.FREQUENCY_POOL_113_114_115}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const nominateFrequency = async (
  id: number,
  params: {
    frequency_pool_id: number
    field_110_from: string
    value_144: string
    field_102: string
  },
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.put(
      `${SpectrumManagerEndpoints.NOMINATE_FREQUENCY}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const updateSfaf = async (
  id: number,
  params: {
    sfaf: string
  },
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.put(
      `${SpectrumManagerEndpoints.SFAF}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const importSfaf = async (
  fieldsData: {
    [key: string]: string
  }[],
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.post(SpectrumManagerEndpoints.SFAF, {
      fieldsData,
    })

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}
