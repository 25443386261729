import {
  Box,
  Modal,
  Button,
  TextField,
  Typography,
  IconButton,
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { IPlatform } from '../../../../types/spectrum-manager.type'

import styles from './styles.module.scss'

interface IProps<T> {
  open: boolean
  fields: { key: string; column: string; isTag: boolean }[]
  platform: IPlatform
  isLightMode: boolean
  onClose: () => void
  handleSave: () => void
  handleChange: (key: string, value: string) => void
}

const PlatformModal = <T extends Record<string, any>>({
  open,
  fields,
  platform,
  isLightMode,
  onClose,
  handleSave,
  handleChange,
}: IProps<T>) => {
  return (
    <Modal className={styles.modal} open={open} onClose={onClose}>
      <Box className={styles.modalContent}>
        <Box className={styles.content}>
          <Box
            className={styles.header}
            sx={{
              borderBottom: isLightMode ? '1px solid black' : '1px solid white',
            }}
          >
            <Typography className={styles.title} id="title">
              Platform Details
            </Typography>
            <IconButton onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={styles.content}>
            <Box className={styles.fields}>
              {fields.slice(1).map((field) => (
                <Box className={styles.fieldContainer}>
                  <Typography>{field.column}:</Typography>
                  <TextField
                    value={(platform as any)[field.key]}
                    className={styles.field}
                    size="small"
                    onChange={(evt) =>
                      handleChange(field.key, evt.target.value)
                    }
                  />
                </Box>
              ))}
              <Box className={styles.fieldContainer}>
                <Typography></Typography>
                <Button variant="outlined" onClick={handleSave}>
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default PlatformModal
