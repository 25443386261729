import { FC, useState, useEffect, useContext, useLayoutEffect } from 'react'
import { Edge } from '@xyflow/react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Drawer, IconButton, TextField } from '@mui/material'
import MapInfo from '../NodeInfo/MapInfo'
import RequestInfo from '../NodeInfo/Request'
import InterferenceInfo from '../NodeInfo/InterferenceInfo'
import PostRequestInfo from '../NodeInfo/PostRequestInfo'
import { useToast } from '../../../../contexts/toast.context'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { updatePlaybook } from '../../../../services/playbook.service'
import { initialFilter } from '../../../../utils/constants'
import { AddNodeTypes, INodeData } from '../../../../types/node.type'

import styles from './styles.module.scss'

interface IProps {
  type: AddNodeTypes
  isOpen: boolean
  label: string
  isEditing?: boolean
  defaultData: INodeData | {}
  handleAdd: (data: INodeData) => void
  handleClose: () => void
}

const NodeDrawer: FC<IProps> = ({
  isOpen,
  type,
  label,
  defaultData,
  isEditing,
  handleAdd,
  handleClose,
}) => {
  const [data, setData] = useState<any>({})
  const {
    isLightMode,
    playbook,
    nodes,
    edges,
    setNodes,
    setIsEditing,
    setPlaybook,
    handleUpdate,
  } = useContext(PlaybookContext)
  const { showToast } = useToast()

  const onClose = () => {
    handleClose()
    setData({})
    setIsEditing(false)
  }

  const handleChange = (key: string, value: string | number) => {
    setData({ ...data, [key]: value })

    if (isEditing && playbook) {
      const updatedNodes = nodes.map((node) =>
        +node.id === data.nodeId
          ? { ...node, data: { ...node.data, [key]: value } }
          : node,
      )

      setPlaybook({ ...playbook, nodes: updatedNodes })
    }
  }

  const handleSave = async () => {
    if (playbook) {
      const updatedNodes = nodes.map((node) =>
        node.data.nodeId === data.nodeId ? { ...node, data } : node,
      )

      const res = await updatePlaybook({ ...playbook, nodes: updatedNodes })

      if (res.success && res.data) {
        showToast('success', res.message)
        setPlaybook(() => ({
          ...playbook,
          nodes: updatedNodes,
          edges: edges as Array<Edge & { label?: string; data: any }>,
        }))

        handleUpdate({ ...playbook, nodes: updatedNodes })
        onClose()
        setIsEditing(false)
      } else {
        showToast('error', res.message)
      }
    }
  }

  const NodeTypeContent = () => {
    switch (type) {
      case AddNodeTypes.ACTION:
        return (
          <TextField
            onChange={(evt) => handleChange('deviceId', +evt.target.value)}
            className={styles.input}
            value={data.deviceId}
            label="Device Id"
          />
        )
      case AddNodeTypes.SLIDER:
        return <InterferenceInfo data={data} setData={setData} />
      case AddNodeTypes.MAP:
        return <MapInfo data={data} setData={setData} />
      case AddNodeTypes.REQUEST_ID:
        return (
          <RequestInfo
            data={data}
            isEditing={true}
            setData={setData}
            isLightMode={isLightMode}
            initialFilters={data.filters || [initialFilter]}
            initialMethod={data.requestMethod}
          />
        )
      case AddNodeTypes.POST_REQUEST:
        return (
          <PostRequestInfo
            data={data}
            setData={setData}
            isLightMode={isLightMode}
          />
        )
      case AddNodeTypes.EMAIL:
        return (
          <TextField
            value={data.email}
            onChange={(evt) => handleChange('email', evt.target.value)}
            className={styles.input}
            label="email address"
          />
        )
    }
  }

  useEffect(() => {
    if (!isEditing) setData({ label })
  }, [label])

  useLayoutEffect(() => {
    if (isEditing) setData(defaultData)
  }, [defaultData])

  return (
    <Drawer
      className={styles.drawer}
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: isLightMode ? 'white' : 'rgb(19, 34, 46) !important',
        },
      }}
      classes={{
        paper: styles.drawerPaper,
      }}
    >
      <Box className={styles.drawerHeader}>
        <IconButton onClick={onClose}>
          <CloseIcon className={styles.closeIcon} />
        </IconButton>
      </Box>
      <Box className={styles.drawerContent}>{NodeTypeContent()}</Box>
      <Box className={styles.actions}>
        <Button
          className={styles.saveBtn}
          onClick={() => (isEditing ? handleSave() : handleAdd(data))}
        >
          Save
        </Button>
        <Button className={styles.cancelBtn} onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Drawer>
  )
}

export default NodeDrawer
