const convertFrequencyToMHz = (frequency: string) => {
  const prefix = frequency[0]
  const value = parseFloat(frequency.slice(1))

  switch (prefix) {
    case 'K':
      return value / 1000 // Convert kHz to MHz
    case 'M':
      return value // Already in MHz
    default:
      throw new Error('Unknown frequency prefix')
  }
}

export default convertFrequencyToMHz
