import React, { useRef, useState } from 'react'
import scss from './styles.module.scss'
import Button from '@mui/material/Button'

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: string
  clearAfterUpload?: boolean
  onFileSelect: (files: FileList | null) => void
}

const FileUploader: React.FC<IProps> = ({
  label,
  error,
  onFileSelect,
  clearAfterUpload,
  ...props
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [fileName, setFileName] = useState<string>('')

  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    onFileSelect(files)
    if (clearAfterUpload) {
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    } else {
      setFileName(files?.[0]?.name || '')
    }
  }

  return (
    <div className={scss.container}>
      {label && <label className={scss.label}>{label}</label>}
      <div className={scss.inputWrapper}>
        <input
          ref={fileInputRef}
          type="file"
          className={scss.input}
          onChange={handleFileChange}
          {...props}
        />
        <Button
          variant="contained"
          onClick={handleButtonClick}
          className={scss.button}
        >
          Upload File
        </Button>
        {fileName && <span className={scss.fileName}>{fileName}</span>}
      </div>
      {error && <span className={scss.error}>{error}</span>}
    </div>
  )
}

export default FileUploader
