import { FC, useState, useEffect, useContext, useRef } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { SelectComponent } from '../../../shared/SelectComponent'
import {
  getSfafAgencyOverride,
  getAllHopsets,
  getAllSFAFAntennas,
  getAllSFAFContents,
  getAllSFAFEquipments,
  getAllSFAFExcludedFrequencies,
  getAllSFAFFrequencies,
  getAllSFAFStationClasses,
  getAssignedFrequenciesByFrequencyId,
  getEquipmentsByFrequencyId,
  getFrequencyById,
  updateAssignedFrequencyById,
  updateEquipmentById,
  updateExerciseRequestStatus,
  updateFrequencyById,
} from '../../../../services/spectrum-manager.service'
import { IExerciseRequestFrequency } from '../../../../types/exercise.type'
import { IExerciseRequestEquipment } from '../../../../types/equipment.type'
import {
  IAssignedFrequency,
  IExerciseRequestDetails,
} from '../../../../types/spectrum-manager.type'
import RFAExportModal from '../../Modals/RfaExportModal'
import { downloadPdf } from '../../../../services/playbook.service'
import { generateSFAF } from '../../../../utils/sfaf-generator'
import { isValidBase64 } from '../../../../utils/functions'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'

import styles from './styles.module.scss'

interface IProps {
  isOpen: boolean
  request: IExerciseRequestDetails
  isEditing?: boolean
  handleClose: () => void
}

const SFAFDrawer: FC<IProps> = ({ isOpen, request, handleClose }) => {
  const [sfaf, setSfaf] = useState('')
  const [isExporting, setIsExporting] = useState(false)
  const [frequency, setFrequency] = useState<IExerciseRequestFrequency | null>(
    null,
  )
  const [equipments, setEquipments] = useState<IExerciseRequestEquipment[]>([])
  const [assignedFrequencies, setAssignedFrequencies] = useState<
    IAssignedFrequency[]
  >([])

  const { isLightMode } = useContext(PlaybookContext)
  const { filterOptions, setDetailedExerciseRequests } = useContext(
    SpectrumManagerContext,
  )

  const pdfRef = useRef<HTMLAnchorElement>(null)
  const linkRef = useRef<HTMLAnchorElement>(null)

  const onClose = () => {
    handleClose()
  }

  const handleExport = () => {
    if (linkRef.current && sfaf) {
      const file = new Blob([sfaf], { type: 'text/plain' })
      linkRef.current.href = URL.createObjectURL(file)
      linkRef.current.download = 'sfaf.txt'

      linkRef.current.click()
    }
  }

  const handleChange = async (
    type: string,
    id: number,
    key: string,
    value: string | number,
  ) => {
    if (type === 'assigned freq') {
      const updatedFrequencies = assignedFrequencies.map((assignedFreq) =>
        id === assignedFreq.id
          ? {
              ...assignedFreq,
              [key]: value,
            }
          : assignedFreq,
      )

      setAssignedFrequencies(updatedFrequencies)

      for (const assignedFreq of updatedFrequencies) {
        await updateAssignedFrequencyById(assignedFreq.id, assignedFreq)
      }
    } else if (type === 'equipment') {
      const updatedEquipments = equipments.map((equipment) =>
        id === equipment.id
          ? {
              ...equipment,
              [key]: value,
            }
          : equipment,
      )

      setEquipments(updatedEquipments)

      for (const equipment of updatedEquipments) {
        await updateEquipmentById(equipment.id, equipment)
      }
    } else if (type === 'freq' && frequency) {
      const updatedFrequency = {
        ...frequency,
        [key]: value,
      }

      setFrequency(updatedFrequency)
      await updateFrequencyById(updatedFrequency.id, updatedFrequency)
    } else if (type === 'request') {
      await updateExerciseRequestStatus(id, value as string)
      setDetailedExerciseRequests((prev) =>
        prev.map((request) =>
          request.request_id === id
            ? { ...request, status: value as string }
            : request,
        ),
      )
    }
  }

  const handleProcessAll = async () => {
    for (const assignedFreq of assignedFrequencies) {
      await updateAssignedFrequencyById(assignedFreq.id, {
        ...assignedFreq,
        status: 'ACCEPTED',
      })
    }
  }

  const getFrequency = async () => {
    const data = await getFrequencyById(request.frequency_id)

    if (data.data && data.success) {
      setFrequency(data.data)
    }
  }

  const getAssignedFrequencies = async () => {
    const data = await getAssignedFrequenciesByFrequencyId(request.frequency_id)

    if (data.data && data.success) {
      setAssignedFrequencies(data.data)
    }
  }

  const getEquipments = async () => {
    const data = await getEquipmentsByFrequencyId(request.frequency_id)

    if (data.data && data.success) {
      setEquipments(data.data)
    }
  }

  const handleSFAFGenerate = async () => {
    const sfafAntennas = await getAllSFAFAntennas(filterOptions)
    const sfafContents = await getAllSFAFContents(filterOptions)
    const sfafEquipments = await getAllSFAFEquipments(filterOptions)
    const sfafFrequencies = await getAllSFAFFrequencies(filterOptions)
    const sfafStationClasses = await getAllSFAFStationClasses(filterOptions)
    const sfafExcludedFrequencies =
      await getAllSFAFExcludedFrequencies(filterOptions)
    const sfafAgencyOverride = await getSfafAgencyOverride(request.user_id)
    const hopsets = await getAllHopsets()

    if (
      sfafFrequencies.data &&
      sfafEquipments.data &&
      sfafContents.data &&
      sfafStationClasses.data &&
      sfafExcludedFrequencies.data &&
      sfafAntennas.data &&
      hopsets.data
    ) {
      const sfaf = await generateSFAF(
        'single',
        sfafFrequencies.data,
        sfafContents.data,
        sfafExcludedFrequencies.data,
        sfafEquipments.data,
        hopsets.data,
        request.frequency_id,
        sfafStationClasses.data,
        sfafAntennas.data,
        sfafAgencyOverride.data,
      )

      setSfaf(sfaf)
    }
  }

  const base64ToBlob = (base64: string) => {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: 'application/pdf' })
  }

  const downloadSinglePdf = async (subject: string) => {
    const data = await downloadPdf(
      subject,
      request.request_id,
      request.site_location.split(',').map((str) => str.trim()),
      [
        new Date(request.start_date).getTime(),
        new Date(request.end_date).getTime(),
      ],
    )

    if (data && isValidBase64(data)) {
      const blob = base64ToBlob(data)

      const blobUrl = URL.createObjectURL(blob)

      if (pdfRef.current) {
        pdfRef.current.href = blobUrl
        pdfRef.current.click()
      }
    }
  }

  useEffect(() => {
    if (request.frequency_id) {
      getFrequency()
      getEquipments()
      getAssignedFrequencies()
    }
  }, [request])

  return (
    <Drawer
      className={styles.drawer}
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: isLightMode ? 'white' : 'rgb(19, 34, 46) !important',
        },
      }}
      classes={{
        paper: styles.drawerPaper,
      }}
    >
      <Box className={styles.drawerHeader}>
        <IconButton onClick={onClose}>
          <CloseIcon className={styles.closeIcon} />
        </IconButton>
      </Box>
      <Box className={styles.drawerContent}>
        <Box className={styles.freqStatusContainer}>
          <Box className={styles.fieldContainer}>
            <Typography>Request Status</Typography>
            <SelectComponent
              items={['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED', 'TEST'].map(
                (value) => ({ value, displayValue: value }),
              )}
              defaultValue={request.status}
              className={styles.select}
              handleChange={(value) =>
                handleChange(
                  'request',
                  request.request_id,
                  'status',
                  String(value),
                )
              }
            />
          </Box>
          <Box className={styles.rfaExportContainer}>
            <Button variant="outlined" onClick={() => setIsExporting(true)}>
              RFA Export
            </Button>
          </Box>
        </Box>
        <Box className={styles.section}>
          <Box className={styles.titleContainer}>
            <Typography className={styles.title}>
              Assign Frequencies - {assignedFrequencies[0]?.start_indicator}
              {assignedFrequencies[0]?.start_freq}
              {'-'}
              {assignedFrequencies[0]?.end_indicator}
              {assignedFrequencies[0]?.end_freq}
            </Typography>
            <Button
              className={styles.btn}
              variant="outlined"
              onClick={handleProcessAll}
              sx={isLightMode ? {} : { backgroundColor: '#13222e' }}
            >
              Process All
            </Button>
          </Box>
          {assignedFrequencies.map((assignedFrequency) => (
            <Box className={styles.assignedFreq} key={assignedFrequency.id}>
              <TextField
                className={styles.input}
                placeholder="Override Id"
                value={assignedFrequency.id_override}
                onChange={(evt) =>
                  handleChange(
                    'assigned freq',
                    assignedFrequency.id,
                    'id_override',
                    evt.target.value,
                  )
                }
              />
              <TextField
                className={styles.input}
                placeholder="frequency"
                value={assignedFrequency.frequency}
                onChange={(evt) =>
                  handleChange(
                    'assigned freq',
                    assignedFrequency.id,
                    'frequency',
                    evt.target.value,
                  )
                }
              />
              <TextField
                placeholder="Serial number"
                className={styles.input}
                defaultValue={assignedFrequency.list_serial_number}
                onChange={(evt) =>
                  handleChange(
                    'assigned freq',
                    assignedFrequency.id,
                    'list_serial_number',
                    evt.target.value,
                  )
                }
              />
              <TextField
                className={styles.input}
                placeholder="F_114"
                defaultValue={assignedFrequency.f_114}
                onChange={(evt) =>
                  handleChange(
                    'assigned freq',
                    assignedFrequency.id,
                    'f_114',
                    evt.target.value,
                  )
                }
              />
              <SelectComponent
                items={['NOT_STARTED', 'ACCEPTED', 'APPROVED', 'DENIED'].map(
                  (value) => ({ value, displayValue: value }),
                )}
                defaultValue={assignedFrequency.status}
                className={styles.select}
                handleChange={(value) =>
                  handleChange(
                    'assigned freq',
                    assignedFrequency.id,
                    'status',
                    String(value),
                  )
                }
              />
            </Box>
          ))}
        </Box>
        <Box className={styles.section}>
          <Box className={styles.titleContainer}>
            <Typography className={styles.title}>
              Override/Admin Entries
            </Typography>
          </Box>
          {equipments.map((equipment) => (
            <Box className={styles.equipment}>
              <Box className={styles.titleContainer}>
                <Typography>340: {equipment.equipment_name}</Typography>
              </Box>
              <Box className={styles.fieldContainer}>
                <Typography>343</Typography>
                <TextField
                  defaultValue={equipment.f_343}
                  placeholder="F_343"
                  onChange={(evt) =>
                    handleChange(
                      'equipment',
                      equipment.id,
                      'f_343',
                      evt.target.value,
                    )
                  }
                />
              </Box>
              <Box className={styles.fieldContainer}>
                <Typography>501</Typography>
                <Box className={styles.f_501}>
                  <TextField
                    defaultValue={equipment.f_501_irac}
                    placeholder="F_501 IRAC"
                    onChange={(evt) =>
                      handleChange(
                        'equipment',
                        equipment.id,
                        'f_501_irac',
                        evt.target.value,
                      )
                    }
                  />
                  <TextField
                    defaultValue={equipment.f_501_sps}
                    placeholder="F_501 SPS"
                    onChange={(evt) =>
                      handleChange(
                        'equipment',
                        equipment.id,
                        'f_501_sps',
                        evt.target.value,
                      )
                    }
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        {frequency && (
          <>
            <Box className={styles.antennaContainer}>
              <Typography>Antenna:</Typography>
              <Box className={styles.fields}>
                <TextField
                  placeholder="F_354"
                  defaultValue={frequency.f_354}
                  onChange={(evt) =>
                    handleChange(
                      'freq',
                      frequency.id,
                      'f_354',
                      evt.target.value,
                    )
                  }
                />
                <TextField
                  placeholder="F_454"
                  defaultValue={frequency.f_454}
                  onChange={(evt) =>
                    handleChange(
                      'freq',
                      frequency.id,
                      'f_454',
                      evt.target.value,
                    )
                  }
                />
              </Box>
            </Box>
            <Box className={styles.orientationContainer}>
              <Typography>Orientation:</Typography>
              <Box className={styles.fields}>
                <TextField
                  defaultValue={frequency.f_362}
                  placeholder="F_362"
                  onChange={(evt) =>
                    handleChange(
                      'freq',
                      frequency.id,
                      'f_362',
                      evt.target.value,
                    )
                  }
                />
                <TextField
                  defaultValue={frequency.f_462}
                  placeholder="F_462"
                  onChange={(evt) =>
                    handleChange(
                      'freq',
                      frequency.id,
                      'f_462',
                      evt.target.value,
                    )
                  }
                />
              </Box>
            </Box>
          </>
        )}
        <Box className={styles.actions}>
          <Button
            className={styles.generateBtn}
            variant="outlined"
            onClick={() => handleSFAFGenerate()}
            sx={isLightMode ? {} : { backgroundColor: '#13222e' }}
          >
            Generate SFAF
          </Button>
          <Button
            className={styles.exportBtn}
            variant="outlined"
            onClick={handleExport}
            sx={isLightMode ? {} : { backgroundColor: '#13222e' }}
          >
            Export SFAF
          </Button>
        </Box>
        <Box className={styles.sfafContainer}>
          <textarea
            placeholder="SFAF value"
            style={{
              fontSize: 14,
              width: '100%',
              height: sfaf ? '1000px' : '50px',
              color: isLightMode ? 'black' : 'white',
              backgroundColor: isLightMode ? 'white' : 'rgb(19, 34, 46)',
            }}
            value={sfaf}
          />
        </Box>
      </Box>

      <a ref={linkRef} hidden />
      <a ref={pdfRef} download hidden />
      <RFAExportModal
        handleConfirm={downloadSinglePdf}
        open={isExporting}
        onClose={() => setIsExporting(false)}
      />
    </Drawer>
  )
}

export default SFAFDrawer
