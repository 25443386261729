import { axiosInstance } from './axios.service'
import { IResponse } from '../types/response.type'
import { SchedulerEndpoints } from '../types/endpoint.type'
import { IScheduler, SchedulerFrequencies } from '../types/scheduler.type'

export const getAllSchedulers = async (
  id: number,
): Promise<IResponse<IScheduler[]>> => {
  try {
    const data = await axiosInstance.get(SchedulerEndpoints.GET + id)

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const createScheduler = async (
  frequency: SchedulerFrequencies,
  playbookId: number,
): Promise<IResponse<IScheduler>> => {
  try {
    const data = await axiosInstance.post(SchedulerEndpoints.CREATE, {
      frequency,
      playbookId,
    })

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err.message,
    }
  }
}

export const updateScheduler = async (
  scheduler: IScheduler,
): Promise<IResponse<IScheduler[]>> => {
  try {
    const data = await axiosInstance.put(
      SchedulerEndpoints.UPDATE + scheduler.id,
      scheduler,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}

export const deleteScheduler = async (
  id: number,
): Promise<IResponse<IScheduler[]>> => {
  try {
    const data = await axiosInstance.delete(SchedulerEndpoints.DELETE + id)

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}
