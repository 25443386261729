interface IHeatmapParams {
  lat: number
  lon: number
  frq: number
  rad: number
}

const prepareHeatmapParams = ({ lat, lon, frq, rad }: IHeatmapParams) => ({
  site: 'A1',
  network: 'Testing',
  transmitter: {
    lat,
    lon,
    alt: 2,
    frq,
    txw: 1,
    bwi: 1,
  },
  receiver: {
    lat: 0,
    lon: 0,
    alt: 2,
    rxg: 3,
    rxs: -100,
  },
  antenna: {
    txg: 1.5,
    txl: 0,
    ant: 0,
    azi: 90,
    tlt: 2,
    hbw: 90,
    vbw: 20,
    pol: 'v',
  },
  environment: {
    clt: 'Minimal.clt',
    elevation: 1,
    landcover: 0,
    buildings: 0,
    obstacles: 0,
  },
  output: {
    units: 'metric',
    col: 'RAINBOW.dBm',
    out: 2,
    ber: 2,
    mod: 7,
    nf: -100,
    res: 10,
    rad,
  },
})

export default prepareHeatmapParams
