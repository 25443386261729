import { axiosInstance } from './axios.service'
import { IResponse } from '../types/response.type'
import { AuthEndpoints } from '../types/endpoint.type'
import { IUser } from '../types/auth.type'

export const getUserByEmail = async (
  email: string,
  username: string,
): Promise<IResponse<IUser>> => {
  try {
    const data = await axiosInstance.post(AuthEndpoints.GET, {
      email,
      username,
    })

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}
