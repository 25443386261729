import {
  FC,
  useRef,
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react'
import {
  Box,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  FormControl,
} from '@mui/material'
import {
  createExerciseLocation,
  createExerciseRequest,
} from '../../../../services/exercise-request-form.service'
import { IExerciseRequest } from '../../../../types/exercise.type'
import { ExerciseRequestContext } from '../../../../contexts/exercise-request.context'
import { ExerciseRequestEquipmentContext } from '../../../../contexts/exercise-request-equipment.context'
import { useToast } from '../../../../contexts/toast.context'
import { AuthContext } from '../../../../contexts/auth.context'

import styles from './styles.module.scss'

interface IProps {
  isLightMode: boolean
  setValue: Dispatch<SetStateAction<number>>
}

type ExerciseTypes = 'my requests' | 'all unit requests'

const Sidebar: FC<IProps> = ({ setValue, isLightMode }) => {
  const [exercisesType, setExercisesType] =
    useState<ExerciseTypes>('my requests')
  const [selectedExercise, setSelectedExercise] = useState(0)

  const { showToast } = useToast()

  const {
    exercises,
    exerciseRequests,
    selectedExerciseRequest,
    setSelectedExerciseRequestFrequency,
    setSelectedExerciseRequestLocation,
    getExerciseRequests,
    setExerciseRequests,
    setSelectedExerciseRequest,
  } = useContext(ExerciseRequestContext)

  const { user } = useContext(AuthContext)
  const { setEquipment } = useContext(ExerciseRequestEquipmentContext)

  const sidebarRef = useRef<HTMLDivElement>(null)

  const handleCreate = async () => {
    const data = await createExerciseRequest(selectedExercise)

    if (data.success && data.data) {
      setExerciseRequests([data.data, ...exerciseRequests])
      setSelectedExerciseRequest(data.data)
      setValue(0)

      showToast('success', 'Exercise Request created successfully')

      await createExerciseLocation(data.data.id)
    }
  }

  const handleRequestChange = (request: IExerciseRequest) => {
    setSelectedExerciseRequest(request)
    setEquipment(null)
    setSelectedExerciseRequestFrequency(null)
    setSelectedExerciseRequestLocation(null)
  }

  useEffect(() => {
    getExerciseRequests(exercisesType, selectedExercise || null)
  }, [exercisesType, selectedExercise, user])

  return (
    <Box className={`${styles.sidebar} sub-container`} ref={sidebarRef}>
      <Box className={styles.form}>
        <FormControl sx={{ width: '100%' }}>
          <Select
            className={styles.select}
            value={exercisesType}
            onChange={(evt) =>
              setExercisesType(evt.target.value as ExerciseTypes)
            }
          >
            <MenuItem value="my requests">My Requests</MenuItem>
            <MenuItem value="all unit requests">All Unit Requests</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel
            sx={{ top: 20, color: isLightMode ? 'black' : 'white' }}
            id="exercise"
          >
            Exercise
          </InputLabel>
          <Select
            className={styles.select}
            labelId="exercise"
            value={selectedExercise}
            label="Exercise"
            onChange={(evt) => setSelectedExercise(Number(evt.target.value))}
          >
            {[...exercises, { name: 'None', id: 0 }].map((exercise) => (
              <MenuItem key={exercise.id} value={exercise.id}>
                {exercise.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          onClick={handleCreate}
          className={styles.createBtn}
          variant="outlined"
        >
          Create
        </Button>
      </Box>
      <Typography>Exercise Requests:</Typography>
      {!!exerciseRequests.length && (
        <Box className={styles.exerciseRequests}>
          {exerciseRequests.map((elem) => (
            <Box
              sx={{ cursor: 'pointer' }}
              key={elem.id}
              className={
                elem.id === selectedExerciseRequest?.id
                  ? isLightMode
                    ? styles.exerciseRequest
                    : styles.selectedExerciseRequest
                  : isLightMode
                    ? styles.selectedExerciseRequest
                    : styles.exerciseRequest
              }
              onClick={() => handleRequestChange(elem)}
            >
              <Typography>
                {elem.name
                  ? elem.name
                  : `${elem.username}: ${new Date(elem.created_at).toTimeString()}`}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default Sidebar
