import { FC, useContext, useRef, useState } from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Box, Modal, Button, Typography, IconButton } from '@mui/material'
import {
  getAllHopsets,
  getAllSFAFAntennas,
  getAllSFAFContents,
  getAllSFAFEquipments,
  getAllSFAFExcludedFrequencies,
  getAllSFAFFrequencies,
  getAllSFAFStationClasses,
} from '../../../../services/spectrum-manager.service'
import { generateSFAF } from '../../../../utils/sfaf-generator'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'
import { AuthContext } from '../../../../contexts/auth.context'

import styles from './styles.module.scss'

interface IProps {
  isOpen: boolean
  frequency_id: number
  isLightMode: boolean
  onClose: () => void
}

const AllSFAFsModal: FC<IProps> = ({
  isLightMode,
  isOpen,
  frequency_id,
  onClose,
}) => {
  const [sfaf, setSfaf] = useState('')

  const { user } = useContext(AuthContext)
  const { filterOptions } = useContext(SpectrumManagerContext)

  const linkRef = useRef<HTMLAnchorElement>(null)

  const handleExport = () => {
    if (linkRef.current && sfaf) {
      const file = new Blob([sfaf], { type: 'text/plain' })
      linkRef.current.href = URL.createObjectURL(file)
      linkRef.current.download = 'sfaf.txt'

      linkRef.current.click()
    }
  }

  const handleSFAFGenerate = async () => {
    const sfafAntennas = await getAllSFAFAntennas(filterOptions)
    const sfafContents = await getAllSFAFContents(filterOptions)
    const sfafEquipments = await getAllSFAFEquipments(filterOptions)
    const sfafFrequencies = await getAllSFAFFrequencies(filterOptions)
    const sfafStationClasses = await getAllSFAFStationClasses(filterOptions)
    const sfafExcludedFrequencies =
      await getAllSFAFExcludedFrequencies(filterOptions)
    const hopsets = await getAllHopsets()
    const sfafAgencyOverride = user?.agencyOverride

    if (
      sfafFrequencies.data &&
      sfafEquipments.data &&
      sfafContents.data &&
      sfafStationClasses.data &&
      sfafExcludedFrequencies.data &&
      sfafAntennas.data &&
      hopsets.data
    ) {
      const sfaf = await generateSFAF(
        'single',
        sfafFrequencies.data,
        sfafContents.data,
        sfafExcludedFrequencies.data,
        sfafEquipments.data,
        hopsets.data,
        frequency_id,
        sfafStationClasses.data,
        sfafAntennas.data,
        sfafAgencyOverride,
      )

      setSfaf(sfaf)
    }
  }

  return (
    <Modal className={styles.modal} open={isOpen} onClose={onClose}>
      <Box className={styles.modalContent}>
        <Box>
          <Box className={styles.header}>
            <Typography
              data-testid="title"
              id="enableTitle"
              className={styles.title}
            >
              Generate All SFAFs
            </Typography>
            <IconButton data-testid="closeButton" onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Box className={styles.content}>
            <Box className={styles.actions}>
              <Button sx={{ color: 'white' }} onClick={handleSFAFGenerate}>
                Generate
              </Button>
              <Button
                sx={{ color: 'white' }}
                variant="outlined"
                onClick={handleExport}
              >
                Export SFAF
              </Button>
            </Box>
            <textarea
              placeholder="SFAF value"
              style={{
                fontSize: 14,
                marginTop: 20,
                width: '100%',
                height: '300px',
                color: isLightMode ? 'black' : 'white',
                backgroundColor: isLightMode ? 'white' : 'rgb(19, 34, 46)',
              }}
              value={sfaf}
            />
            <a ref={linkRef} hidden />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default AllSFAFsModal
