import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from '@mui/material'
import React, { FC, useState } from 'react'
import { IHeatmapParams } from '../../../types/cloudRF.type'

interface IProps {
  isOpen: boolean
  onClose: () => void
  onUpdate: (updatedParams: IHeatmapParams) => void
  initialValues: IHeatmapParams
}

const HeatmapSettingsModal: FC<IProps> = ({
  isOpen,
  onClose,
  onUpdate,
  initialValues,
}) => {
  const [formData, setFormData] = useState<IHeatmapParams>(initialValues)

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    const keys = name.split('.')

    setFormData((prev) => {
      const updatedFormData = { ...prev } as any
      let current = updatedFormData

      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]]
      }

      current[keys[keys.length - 1]] = isNaN(+value) ? value : +value
      return updatedFormData
    })
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onUpdate(formData)
    onClose()
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '80vh',
          overflowY: 'auto',
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Change Heatmap Parameters
        </Typography>

        <Box sx={{ flex: 1, overflowY: 'auto' }}>
          <TextField
            fullWidth
            label="Site"
            name="site"
            value={formData.site}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Network"
            name="network"
            value={formData.network}
            onChange={handleChange}
            margin="normal"
          />

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Transmitter</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                label="Transmitter Latitude"
                name="transmitter.lat"
                value={formData.transmitter.lat}
                margin="normal"
                disabled
              />
              <TextField
                fullWidth
                label="Transmitter Longitude"
                name="transmitter.lon"
                value={formData.transmitter.lon}
                margin="normal"
                disabled
              />
              {Object.keys(formData.transmitter).map((key) => {
                if (key !== 'lat' && key !== 'lon') {
                  return (
                    <TextField
                      key={key}
                      fullWidth
                      label={`Transmitter ${key}`}
                      name={`transmitter.${key}`}
                      value={(formData.transmitter as any)[key]}
                      onChange={handleChange}
                      margin="normal"
                    />
                  )
                }
                return null
              })}
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Receiver</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {Object.keys(formData.receiver).map((key) => (
                <TextField
                  key={key}
                  fullWidth
                  label={`Receiver ${key}`}
                  name={`receiver.${key}`}
                  value={(formData.receiver as any)[key]}
                  onChange={handleChange}
                  margin="normal"
                />
              ))}
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Antenna</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {Object.keys(formData.antenna).map((key) => (
                <TextField
                  key={key}
                  fullWidth
                  label={`Antenna ${key}`}
                  name={`antenna.${key}`}
                  value={(formData.antenna as any)[key]}
                  onChange={handleChange}
                  margin="normal"
                />
              ))}
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Environment</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {Object.keys(formData.environment).map((key) => (
                <TextField
                  key={key}
                  fullWidth
                  label={`Environment ${key}`}
                  name={`environment.${key}`}
                  value={(formData.environment as any)[key]}
                  onChange={handleChange}
                  margin="normal"
                />
              ))}
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Output</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {Object.keys(formData.output).map((key) => (
                <TextField
                  key={key}
                  fullWidth
                  label={`Output ${key}`}
                  name={`output.${key}`}
                  value={(formData.output as any)[key]}
                  onChange={handleChange}
                  margin="normal"
                />
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ mt: 2 }}
        >
          Simulate
        </Button>
      </Box>
    </Modal>
  )
}

export default HeatmapSettingsModal
