import { axiosInstance } from './axios.service'
import { IResponse } from '../types/response.type'
import { IAdditionalAction } from '../types/additional-actions.type'
import { AdditionalActionsEndpoints } from '../types/endpoint.type'

export const getAllAdditionalActions = async (): Promise<
  IResponse<IAdditionalAction[]>
> => {
  try {
    const data = await axiosInstance.get(AdditionalActionsEndpoints.GET)

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message
        ? err.response.data.message
        : err.message,
    }
  }
}
